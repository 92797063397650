"use client";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import queryString from 'query-string';
import { resetPassword } from "../../api/auth";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const toast = useToast();
  const navigate = useNavigate();

  const parsedHash = queryString.parse(useLocation().hash)
  const accessToken = parsedHash.access_token?.toString();

  const handleSubmit = async () => {
    if (!validatePassword(password, confirmPassword)) {
      return;
    }

    if (!accessToken) {
      toast({
        title: "Invalid token",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    resetPassword(accessToken, password);

    toast({
      title: "Password reset successful!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // reset access token because we will need to login again
    navigate(ROUTES.LOGIN);
  };

  const validatePassword = (password: string, confirmPassword: string) => {
    if (password === "" || confirmPassword === "") {
      toast({
        title: "Please fill in all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    if (password !== confirmPassword) {
      toast({
        title: "Passwords do not match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    // TODO: add some extra check for password strength

    return true;
  };

  const useAnchor = () => {
    const location = useLocation();
    const hash = location.hash;
    return hash ? hash.substring(1): '';
  }

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Enter new password
        </Heading>
        <FormControl id="email" isRequired>
          <FormLabel>Enter your new password</FormLabel>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </FormControl>
        <FormControl id="password" isRequired>
          <FormLabel>Enter your new password again:</FormLabel>
          <Input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button onClick={handleSubmit} color={"white"}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ResetPasswordPage;
