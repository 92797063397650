import { Tag, TagProps } from "@chakra-ui/react";

const tagColorMap: { [key: string]: { text: string; bg: string } } = {
  grey: {
    text: "black",
    bg: "#e6e6e6",
  },
  orange: {
    text: "white",
    bg: "#ef6c00",
  },
  red: {
    text: "white",
    bg: "#ff5449",
  },
  green: {
    text: "white",
    bg: "#42b14a",
  },
};

interface TagPillProps {
  tagColor: string;
  name: string;
}

export const TagPill = ({
  tagColor,
  name,
  ...others
}: TagPillProps & TagProps) => {
  tagColor = tagColor.toLowerCase();
  return (
    <Tag
      backgroundColor={tagColorMap[tagColor]?.bg}
      color={tagColorMap[tagColor]?.text}
    >
      {name}
    </Tag>
  );
};
