import {
  Avatar,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Text,
  VStack,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import { funEmoji } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useEffect, useState } from "react";
import { updatePatient } from "../../../api/patients";
import { PatientDto } from "../../../types/patient";
import { ProviderType } from "../../../types/workflow";
import { toTitleCase } from "../../../utils";

interface SidePatientDetailProps {
  patient: PatientDto;
}

const SidePatientDetail = ({ patient }: SidePatientDetailProps) => {
  const [initialPatient, setInitialPatient] = useState<PatientDto>(patient);
  const [updatedPatient, setUpdatedPatient] = useState<PatientDto>(patient);
  const [image, setImage] = useState<string>();
  const toast = useToast();

  useEffect(() => {
    const getAvatar = async () => {
      const avatar = await createAvatar(funEmoji, {
        seed: initialPatient.name,
      }).toDataUri();
      setImage(avatar);
    };

    getAvatar();
  }, [initialPatient.name]);

  const isSame = (a: PatientDto, b: PatientDto) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const updated = await updatePatient(updatedPatient.id, updatedPatient);
    if (updated) {
      setInitialPatient(updated.data);
      setUpdatedPatient(updated.data);
      toast({
        title: "Updated client",
        description: `${updatedPatient.name} has been updated successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Failed to update client",
        description: "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack
      maxW={"250px"}
      px={8}
      align={"start"}
      as="form"
      onSubmit={handleSubmit}
      borderRight={"1px solid #E2E8F0"}
      overflow={"hidden"}
    >
      <HStack>
        <WrapItem>
          <Avatar name={updatedPatient.name} src={image} />
        </WrapItem>
        <Heading size={"lg"}>{updatedPatient?.name}</Heading>
      </HStack>
      <Divider my={4} />
      <VStack align={"start"}>
        <Text fontSize={"lg"} fontWeight={"bold"}>
          Client Data
        </Text>
        <FormControl isRequired>
          <FormLabel>Nationality</FormLabel>
          <Input
            value={updatedPatient.nationality}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationality: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>National ID</FormLabel>
          <Input
            value={updatedPatient?.nationalId}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                nationalId: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Date of Birth</FormLabel>
          <SingleDatepicker
            triggerVariant="input"
            name="date-input"
            date={updatedPatient.dob}
            maxDate={new Date()}
            onDateChange={(newDate: Date) => {
              updatedPatient &&
                setUpdatedPatient({
                  ...updatedPatient,
                  dob: new Date(newDate),
                });
            }}
            configs={{
              dateFormat: "d/M/y",
            }}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Sex</FormLabel>
          <Select
            value={updatedPatient.sex}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, sex: e.target.value })
            }
          >
            <option value="">Select one</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>District</FormLabel>
          <Input
            value={updatedPatient.district}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, district: e.target.value })
            }
          />
        </FormControl>
        <Text fontSize={"lg"} fontWeight={"bold"} mt={4}>
          Communication
        </Text>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            value={updatedPatient.email}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({ ...updatedPatient, email: e.target.value })
            }
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Phone Number</FormLabel>
          <Input
            value={updatedPatient.whatsappPhoneNum}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                whatsappPhoneNum: e.target.value,
              })
            }
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Preferred Channel</FormLabel>
          <Select
            value={updatedPatient.preferredChannel}
            onChange={(e) =>
              updatedPatient &&
              setUpdatedPatient({
                ...updatedPatient,
                preferredChannel:
                  ProviderType[e.target.value as keyof typeof ProviderType],
              })
            }
          >
            {Object.keys(ProviderType).map((key) => {
              return (
                <option key={key} value={key}>
                  {toTitleCase(key)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </VStack>
      <Button
        mt={4}
        w={"full"}
        isDisabled={isSame(initialPatient, updatedPatient)}
        mr={3}
        type="submit"
      >
        Save
      </Button>
    </VStack>
  );
};

export default SidePatientDetail;
