import Chart, { ChartData, ChartOptions } from "chart.js/auto";
import { useEffect, useRef } from "react";

interface LineChartComponentProps {
  data: ChartData;
  options?: ChartOptions;
}

const LineChartComponent = ({ data, options }: LineChartComponentProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        if (chartRef.current) {
          chartRef.current.destroy();
        }

        chartRef.current = new Chart(ctx, {
          type: "line",
          data: data,
          options: options || {},
        });
      }
    }

    return () => {
      chartRef.current?.destroy();
    };
  }, [data, options]); // Dependencies array includes data and options

  return <canvas ref={canvasRef} />;
};

export default LineChartComponent;
