import { Input } from "@chakra-ui/react";
import { WorkflowField } from "../../types/workflow";

interface ShortTextFormFieldProps {
  field: WorkflowField;
  handleInputChange: (key: string, value: string) => void;
}

export const ShortTextFormField = ({
  field,
  handleInputChange,
}: ShortTextFormFieldProps) => {
  return (
    <Input
      type="text"
      placeholder={"Enter your response..."}
      onChange={(e) => handleInputChange(field.key, e.target.value)}
    />
  );
};
