import { Box, Text, VStack } from "@chakra-ui/react";
import { MouseEventHandler } from "react";
import { theme } from "../../constants";

type FlowTemplateCardProps = {
  title: string;
  description: string;
  id: string;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  [key: string]: any;
};

const FlowTemplateCard = ({
  title,
  description,
  id,
  selected,
  onClick,
  ...boxProps
}: FlowTemplateCardProps) => {
  return (
    <Box
      borderColor={selected ? theme : undefined}
      onClick={onClick}
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="md"
      width="260px"
      height="200px"
      cursor="pointer"
      {...boxProps}
    >
      <VStack
        userSelect={"none"}
        w={"full"}
        h={"full"}
        spacing={2}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <Text fontSize="xl" fontWeight="bold">
          {title}
        </Text>
        <Text color="gray.500">{description}</Text>
      </VStack>
    </Box>
  );
};

export default FlowTemplateCard;
