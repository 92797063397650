import { create } from "zustand";
import { persist } from "zustand/middleware";
import { logout } from "../api/auth";
import { LoginDto } from "../types/auth";
import { StaffDto } from "../types/staff";

export type StaffState = {
  accessToken: string;
  currentStaff: StaffDto | null;
  login: (data: LoginDto) => void;
  refreshToken: (data: { accessToken: string }) => void;
  logout: () => void;
};

export const useStaffStore = create<StaffState>()(
  persist(
    (set) => ({
      accessToken: "",
      currentStaff: null,
      login: ({ accessToken, staff }: LoginDto) => {
        set({
          accessToken,
          currentStaff: staff,
        })
      },
      refreshToken: ({ accessToken }) => set({ accessToken }),
      logout: () => {
        set({
          accessToken: "",
          currentStaff: null,
        });
        logout();
      },
    }),
    {
      name: "user-storage",
    }
  )
);