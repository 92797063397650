import { ResponseType, WorkflowDto, WorkflowField } from "../types/workflow";

export const checkFormValid = (
  workflow: WorkflowDto,
  form: { [key: string]: string }
): { success: boolean; errorMsg: string } => {
  for (let i = 0; i < workflow.fields.length; i++) {
    const field = workflow.fields[i];
    const fieldValue = form[field.key];
    if (field.required && fieldValue === "") {
      return {
        success: false,
        errorMsg: `Response for question ${i + 1} is required`,
      };
    }

    if (
      (field.responseType === ResponseType.NUMBER ||
        field.responseType === ResponseType.PROPERTY) &&
      checkNumberFieldInvalid(field, fieldValue)
    ) {
      return {
        success: false,
        errorMsg: `Response for question ${i + 1} must be within range.`,
      };
    }
  }

  return { success: true, errorMsg: "" };
};

export const checkNumberFieldInvalid = (
  field: WorkflowField,
  value: string
) => {
  let maxInvalid = false;
  let minInvalid = false;
  if (
    field.numberOptions?.max !== undefined &&
    parseFloat(value) > field.numberOptions.max
  ) {
    maxInvalid = true;
  }

  if (
    field.numberOptions?.min !== undefined &&
    parseFloat(value) < field.numberOptions.min
  ) {
    minInvalid = true;
  }

  return maxInvalid || minInvalid;
};
