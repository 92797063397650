import axios, { AxiosResponse } from "axios";

// tokenRefresher.js
let refreshInProgress = false;
let refreshPromise: Promise<AxiosResponse<any, any>> | null = null;

const API_URL = process.env.REACT_APP_API_URL || "";

async function refreshTokenHelper() {
  if (!refreshInProgress) {
    refreshInProgress = true;
    refreshPromise = axios
      .post(
        `${API_URL}/auth/refresh`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // Resolve with response and reset for next time
        refreshInProgress = false;
        refreshPromise = null;
        return response;
      })
      .catch((error) => {
        // Reject with error and reset for next time
        refreshInProgress = false;
        refreshPromise = null;
        throw error;
      });
  }
  return refreshPromise;
}

export default refreshTokenHelper;
