import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import RadioOptions from "../../../components/Flows/RadioOptions";
import {
  OptionsLayout,
  ResponseType,
  WorkflowField,
} from "../../../types/workflow";

interface RadioFlowFieldProps {
  field: WorkflowField;
  updateField?: (field: WorkflowField) => void;
}

const RadioFlowField = ({ field, updateField }: RadioFlowFieldProps) => {
  const parseOptions = (options: string) => {
    const optionsArray = options
      .split(";")
      .filter((option) => option.trim() !== "");
    if (optionsArray.length === 0) {
      return {};
    }

    const optionsObject = optionsArray.reduce((acc, option) => {
      acc[option] = option;
      return acc;
    }, {} as Record<string, string>);
    return optionsObject;
  };
  return (
    <VStack align={"left"}>
      <FormControl
        isRequired
        display={"flex"}
        flexDir={"row"}
        alignItems={"center"}
      >
        <HStack w={"150px"}>
          <FormLabel m={0}>Options</FormLabel>
          <Tooltip shouldWrapChildren label="Radio options for the question.">
            <AiOutlineInfoCircle />
          </Tooltip>
        </HStack>
        <Input
          value={Object.keys(field.options || {}).join(";")}
          onChange={(e) => {
            const newField: WorkflowField = {
              ...field,
              options: parseOptions(e.target.value),
            };
            updateField && updateField(newField);
          }}
          placeholder="Enter options separated by ;"
        />
      </FormControl>
      <HStack>
        <Text w={"150px"}>Layout</Text>
        <Select
          value={field.layout}
          onChange={(e) => {
            const newField: WorkflowField = {
              ...field,
              layout: e.target.value as OptionsLayout,
            };
            updateField && updateField(newField);
          }}
        >
          <option value={OptionsLayout.VERTICAL}>Vertical</option>
          <option value={OptionsLayout.HORIZONTAL}>Horizontal</option>
        </Select>
      </HStack>
      <HStack>
        <Text w={"150px"}>Labels</Text>
        <Flex flexWrap={"wrap"}>
          <Input
            m={2}
            w="fit-content"
            onChange={(e) => {
              const newField: WorkflowField = {
                ...field,
                rangeLabels: {
                  ...field.rangeLabels,
                  low: e.target.value,
                },
              };
              updateField && updateField(newField);
            }}
            placeholder="Min Label"
          />
          <Input
            m={2}
            w="fit-content"
            onChange={(e) => {
              const newField: WorkflowField = {
                ...field,
                rangeLabels: {
                  ...field.rangeLabels,
                  high: e.target.value,
                },
              };

              updateField && updateField(newField);
            }}
            placeholder="Max Label"
          />
        </Flex>
      </HStack>
      <Box>
        {field.options && field.responseType === ResponseType.RADIO && (
          <RadioOptions
            rangeLabels={field.rangeLabels}
            layout={field.layout}
            options={field.options}
          />
        )}
      </Box>
    </VStack>
  );
};

export default RadioFlowField;
