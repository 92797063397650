import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { getAllPatientTagsByTagId } from "../../api/patientTags";
import { getTagById } from "../../api/tag";
import Header from "../../components/Head";
import TablePagination from "../../components/TablePagination";
import { PatientTagsTable } from "../../components/Tags/PatientTagsTable";
import { ROUTES } from "../../constants";
import { PatientTagDto, TagDto } from "../../types/tag";
import { formatDateToReadableString } from "../../utils/date";

export default function ViewPatientTagsPage() {
  const navigate = useNavigate();
  const { tagId } = useParams();

  const [tag, setTag] = useState<TagDto>();
  const [patientTags, setPatientTags] = useState<PatientTagDto[]>([]);
  const [filter, setFilter] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTags, setTotalTags] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchAllPatientTags();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    fetchTag();
  }, []);

  const fetchTag = async () => {
    const resp = await getTagById(tagId!);
    if (resp.data) {
      setTag(resp.data);
    }
  };

  const fetchAllPatientTags = async () => {
    const resp = await getAllPatientTagsByTagId(
      currentPage,
      itemsPerPage,
      tagId!
    );
    if (resp.data) {
      if (resp.data.data) {
        setPatientTags(resp.data.data);
      }

      setTotalTags(resp.data.total);
    }
  };

  return (
    <>
      <Header description="Patients with current tags" />
      <Container maxW={"5xl"}>
        <Stack>
          <Flex justifyContent={"space-between"}>
            <VStack align={"left"}>
              <HStack
                cursor={"pointer"}
                alignItems={"center"}
                color={"gray"}
                onClick={() => navigate(ROUTES.TAGS)}
              >
                <FaChevronLeft />
                <Text>Back to tags</Text>
              </HStack>
              <Heading>{tag?.name}</Heading>
              <Text>{totalTags} patients</Text>
              <Text>
                Created by {tag?.createdBy} on{" "}
                {formatDateToReadableString(tag?.createdAt!)}
              </Text>
            </VStack>
            <Button
              minW={100}
              onClick={() => {
                navigate(`${ROUTES.TAGS}/manage/${tagId}`);
              }}
            >
              MANAGE
            </Button>
          </Flex>

          <Heading as="h3" size="lg">
            Clients with this tag
          </Heading>
          <Text>
            Last Updated {formatDateToReadableString(tag?.updatedAt!)}
          </Text>

          <Box mt={6}>
            <PatientTagsTable patientTags={patientTags} />
            <TablePagination
              currentPage={currentPage}
              totalRows={totalTags}
              currentPageRows={patientTags.length}
              onPageChange={setCurrentPage}
              rowsPerPage={itemsPerPage}
              onChangeRowsPerPage={setItemsPerPage}
            />
          </Box>
        </Stack>
      </Container>
    </>
  );
}
