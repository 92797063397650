// chakra ui react component for a card with some text and a pill to show the current status

import { Card, CardBody, Heading, Stack, Tag, Text } from "@chakra-ui/react";
import { RRule } from "rrule";
import { getReadableRRule } from "../../constants/time";
import { WorkflowStatus } from "../../types/workflow";
import { getStatusColor } from "../../utils";

interface FlowCardProps {
  status: WorkflowStatus;
  onClick?: () => void;
  name: string;
  frequency: RRule;
  numPatients: number;
  numResponses: number;
}

const FlowCard = ({
  status,
  name,
  frequency,
  numPatients,
  numResponses,
  onClick,
}: FlowCardProps) => {
  return (
    <Card maxW="sm" onClick={onClick} cursor={"pointer"}>
      <CardBody>
        <Stack mt="4" spacing="1">
          <Tag
            variant="solid"
            colorScheme={getStatusColor(status)}
            maxW={"fit-content"}
            textTransform={"capitalize"}
          >
            {status.toLowerCase()}
          </Tag>
          <Heading size="md" my={4}>
            {name}
          </Heading>
          <Text>{getReadableRRule(frequency.toString())}</Text>
          <Text>{numPatients} respondent(s) enrolled</Text>
          <Text mt={4}>{numResponses} responses</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default FlowCard;
