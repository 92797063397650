import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import SidebarWithHeader from "../SidebarWithHeader";

const Layout = () => (
  <>
    <SidebarWithHeader>
      <Box mt={10} mb={10} overflow={"auto"}>
        <Outlet />
      </Box>
    </SidebarWithHeader>
  </>
);

export default Layout;
