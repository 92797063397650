import {
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { searchPatientMetrics } from "../../../api/patientMetric";
import { searchNote } from "../../../api/patients";
import {
  PatientActivity,
  PatientActivityType,
  PatientDto,
  PatientNoteDto,
} from "../../../types/patient";
import { PatientMetricDto } from "../../../types/workflow";
import { toTitleCase } from "../../../utils";
import NotesSection from "../PatientDetail/NotesSection";
import { getGroupDateHeader, groupActivitiesByMonth } from "../utils";

interface PatientInfoSearchProps {
  patient: PatientDto;
  onSearch: (search: string) => void;
}

const SearchBar = ({
  handleSearch,
}: {
  handleSearch: (search: string) => void;
}) => {
  const [search, setSearch] = useState<string>("");
  return (
    <>
      <Text fontSize={"lg"} fontWeight={"bold"}>
        Search for Client Information:
      </Text>
      <HStack>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          bgColor={"white"}
          fontSize={"xl"}
          placeholder="Search for client information, e.g. Blood Pressure"
        />
        <Button onClick={() => handleSearch(search)}>Search</Button>
      </HStack>
    </>
  );
};

const PatientInfoSearch = ({ patient, onSearch }: PatientInfoSearchProps) => {
  const [searchedActivities, setSearchedActivities] = useState<
    PatientActivity[][] | undefined
  >();

  const handleSearch = async (search: string) => {
    if (!search || !patient?.id) {
      onSearch(search);
      setSearchedActivities(undefined);
      return;
    }

    const metricsResult = searchPatientMetrics({
      patientId: patient.id,
      search,
    });
    const notesResult = searchNote({
      patientId: patient.id,
      search,
    });

    const [metrics, notes] = await Promise.all([metricsResult, notesResult]);
    if (!metrics || !notes) {
      return;
    }
    const metricsActivities: PatientActivity[] = metrics.map(
      (metric: PatientMetricDto) => {
        const content = (
          <>
            {metric.category && (
              <>
                <Text display="inline">
                  Category: {toTitleCase(metric.category)}
                </Text>
                <br />
              </>
            )}
            <Text display="inline">{toTitleCase(metric.propertyId)}: </Text>
            <Text
              fontWeight={"normal"}
              whiteSpace={"pre-line"}
              display={"inline"}
            >
              {metric.value} {metric.unit && `(${metric.unit})`}
            </Text>
          </>
        );
        return {
          header: (
            <>
              <Text fontWeight={"bold"} display={"inline"}>
                Patient Property Added
              </Text>
            </>
          ),
          preview: (
            <Text fontSize={"sm"} fontWeight={"normal"} whiteSpace={"pre-line"}>
              {content}
            </Text>
          ),
          type: PatientActivityType.METRIC,
          date: new Date(parseInt(metric.timestamp)),
          content: content,
        };
      }
    );
    const notesActivities: PatientActivity[] = notes.map(
      (note: PatientNoteDto) => {
        return {
          header: (
            <>
              <Text fontWeight={"bold"} display={"inline"}>
                Note{" "}
              </Text>
              <Text fontWeight={"normal"} display={"inline"}>
                from {note.staffName}
              </Text>
            </>
          ),
          preview: (
            <Text fontSize={"sm"} fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
          type: PatientActivityType.NOTE,
          date: new Date(note.createdDate || ""),
          content: (
            <Text fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
        };
      }
    );
    const groupedMetrics = groupActivitiesByMonth(
      [...metricsActivities, ...notesActivities].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )
    );

    setSearchedActivities(groupedMetrics);
    onSearch(search);
  };
  return (
    <>
      <SearchBar handleSearch={handleSearch} />
      {searchedActivities && (
        <>
          <Divider my={4} />
          <Heading fontSize={"lg"}>Results</Heading>
          {searchedActivities.length > 0 &&
            searchedActivities?.map((group) => {
              return (
                <NotesSection
                  activities={group}
                  header={getGroupDateHeader(group)}
                />
              );
            })}
          {searchedActivities.length === 0 && <Text>No results found</Text>}
        </>
      )}
    </>
  );
};

export default PatientInfoSearch;
