import { PatientDto } from "./patient";

export interface TagDto {
  id?: string;
  name: string;
  description: string;
  removeWhenNotMet: boolean;
  tagColor: string;
  createdBy?: string;
  updatedBy?: string;
  tagRules: TagRuleDto[];
  tagCount?: number;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CustomPatientTagDto extends TagDto {
  assigned: Date;
}

export interface ConditionDto {
  property: string;
  operation: Operation;
  value: string;
}

export interface ConditionGroupDto {
  type: "AND" | "OR";
  conditions: ConditionDto[]; //  in future shoulud support nested condition groups as well: (ConditionDto | ConditionGroupDto)[];
}

export interface TagRuleDto {
  id?: string; // Optional for creation
  conditions: ConditionGroupDto;
}

export interface PatientTagDto {
  tagId: string;
  patientId: string;
  ruleId?: string; // Optional since a tag may not always have a rule
  createdAt: Date;
  updatedAt: Date;
  patient?: PatientDto;
}

export enum Operation {
  LT = "LT",
  LTE = "LTE",
  EQ = "EQ",
  GTE = "GTE",
  GT = "GT",
  // LIKE = "LIKE",
}

export enum Property {
  PATIENT_AGE = "PATIENT_AGE",
  BLOOD_GLUCOSE_FASTING = "BLOOD_GLUCOSE_FASTING",
  BLOOD_GLUCOSE_RANDOM = "BLOOD_GLUCOSE_RANDOM",
  BLOOD_PRESSURE_SYSTOLIC = "BLOOD_PRESSURE_SYSTOLIC",
  BLOOD_PRESSURE_DIASTOLIC = "BLOOD_PRESSURE_DIASTOLIC",
  HEIGHT = "HEIGHT",
  WEIGHT = "WEIGHT",

  // Lipid Profile properties
  TOTAL_CHOLESTEROL = "TOTAL_CHOLESTEROL",
  HDL_CHOLESTEROL = "HDL_CHOLESTEROL",
  LDL_CHOLESTEROL = "LDL_CHOLESTEROL",
  CHOLESTEROL_HDL_RATIO = "CHOLESTEROL_HDL_RATIO",
  TRIGLYCERIDES = "TRIGLYCERIDES",
  TOTAL_PROTEIN = "TOTAL_PROTEIN",

  // Liver Profile properties
  ALBUMIN = "ALBUMIN",
  GLOBULIN = "GLOBULIN",
  AG_RATIO = "AG_RATIO",
  BILIRUBIN_TOTAL = "BILIRUBIN_TOTAL",
  ALKALINE_PHOSPHATASE = "ALKALINE_PHOSPHATASE",
  SGPT_ALT = "SGPT_ALT",
  SGOT_AST = "SGOT_AST",
  GAMMA_GT = "GAMMA_GT",

  EXERCISE_MINUTES_PER_DAY = "EXERCISE_MINUTES_PER_DAY",
  BMI = "BMI",
}
