import {
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import { Property } from "../../types/tag";
import { ResponseType, WorkflowField } from "../../types/workflow";
import { toTitleCase } from "../../utils";
import { capitalize, wordify } from "../../utils/strings";
import RadioFlowField from "./FlowFields/RadioFlowField";

type CreateFlowFieldProps = {
  field: WorkflowField;
  index: number;
  removeField?: () => void;
  updateField?: (field: WorkflowField) => void;
};

const CreateFlowField = ({
  field,
  index,
  removeField,
  updateField,
}: CreateFlowFieldProps) => {
  const isNumberRangeInvalid = (field: WorkflowField) => {
    if (
      ![ResponseType.NUMBER, ResponseType.PROPERTY].includes(field.responseType)
    ) {
      return;
    }

    if (field.numberOptions?.max && field.numberOptions?.min) {
      return field.numberOptions.max < field.numberOptions.min;
    }

    return false;
  };
  return (
    <Card width="full" p={6}>
      <VStack spacing={5} align={"left"}>
        <HStack justify={"space-between"}>
          <FormControl isRequired display={"flex"} flexDir={"row"}>
            <FormLabel>Response Type</FormLabel>
            <Select
              mr={5}
              w={"fit-content"}
              value={field.responseType}
              onChange={(e) => {
                const newField: WorkflowField = {
                  ...field,
                  responseType: e.target.value as ResponseType,
                };
                if (newField.responseType !== ResponseType.RADIO) {
                  newField.options = {};
                  newField.rangeLabels = {};
                }
                updateField && updateField(newField);
              }}
            >
              <option value={""}>Select response type</option>
              {Object.keys(ResponseType).map((key) => {
                const label = wordify(key);
                return (
                  <option key={key} value={key}>
                    {capitalize(label)}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {removeField && (
            <FaTrash cursor={"pointer"} color="red" onClick={removeField} />
          )}
        </HStack>
        {field.responseType === ResponseType.PROPERTY && (
          <FormControl isRequired display={"flex"} flexDir={"row"}>
            <HStack w={"150px"}>
              <FormLabel m={0}>Property</FormLabel>
            </HStack>
            <Select
              value={field.property}
              onChange={(e) => {
                const newField: WorkflowField = {
                  ...field,
                  property: e.target.value as Property,
                };
                updateField && updateField(newField);
              }}
            >
              <option value={""}>Select a property</option>
              {Object.keys(Property).map((p) => (
                <option value={p}>{toTitleCase(p)}</option>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl isRequired display={"flex"} flexDir={"row"}>
          <HStack w={"150px"}>
            <FormLabel m={0}>Question</FormLabel>
            <Tooltip
              shouldWrapChildren
              label="This is the question displayed on the form."
            >
              <AiOutlineInfoCircle />
            </Tooltip>
          </HStack>
          <Input
            required
            onChange={(e) => {
              const newField: WorkflowField = {
                ...field,
                question: e.target.value,
              };
              updateField && updateField(newField);
            }}
            value={field.question}
          />
        </FormControl>
        <FormControl isRequired display={"flex"} flexDir={"row"}>
          <HStack w={"150px"}>
            <FormLabel m={0}>Label name</FormLabel>
            <Tooltip
              shouldWrapChildren
              label="This is used for data visualization"
            >
              <AiOutlineInfoCircle />
            </Tooltip>
          </HStack>
          <Input
            onChange={(e) => {
              const newField: WorkflowField = {
                ...field,
                label: e.target.value,
              };
              updateField && updateField(newField);
            }}
            value={field.label}
          />
        </FormControl>

        {field.responseType === ResponseType.RADIO && (
          <RadioFlowField field={field} updateField={updateField} />
        )}

        {(field.responseType === ResponseType.NUMBER ||
          (field.responseType === ResponseType.PROPERTY &&
            field.property !== Property.BMI)) && (
          <FormControl
            display={"flex"}
            flexDir={"row"}
            alignItems={"center"}
            isInvalid
          >
            <FormLabel w={"150px"}>Range</FormLabel>
            <Flex wrap={"wrap"}>
              <HStack align={"center"} m={2}>
                <HStack w={"75px"}>
                  <FormLabel m={0}>Min</FormLabel>
                  <Tooltip
                    shouldWrapChildren
                    label="Minimum value for the number field."
                  >
                    <AiOutlineInfoCircle />
                  </Tooltip>
                </HStack>
                <NumberInput
                  value={field.numberOptions?.min}
                  isInvalid={isNumberRangeInvalid(field)}
                  onChange={(value) => {
                    const newField: WorkflowField = {
                      ...field,
                      numberOptions: {
                        ...field.numberOptions,
                        min: parseInt(value),
                      },
                    };
                    updateField && updateField(newField);
                  }}
                >
                  <NumberInputField />

                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
              <HStack align={"center"} m={2}>
                <HStack w={"75px"}>
                  <FormLabel m={0}>Max</FormLabel>
                  <Tooltip
                    shouldWrapChildren
                    label="Maximum value for the number field."
                  >
                    <AiOutlineInfoCircle />
                  </Tooltip>
                </HStack>
                <NumberInput
                  isInvalid={isNumberRangeInvalid(field)}
                  value={field.numberOptions?.max}
                  onChange={(value) => {
                    const newField: WorkflowField = {
                      ...field,
                      numberOptions: {
                        ...field.numberOptions,
                        max: parseInt(value),
                      },
                    };
                    updateField && updateField(newField);
                  }}
                >
                  <NumberInputField />

                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </HStack>
            </Flex>
          </FormControl>
        )}

        <HStack>
          <Checkbox
            onChange={(e) => {
              const newField: WorkflowField = {
                ...field,
                required: e.target.checked,
              };
              updateField && updateField(newField);
            }}
            isChecked={field.required}
          >
            Required?
          </Checkbox>
        </HStack>
      </VStack>
    </Card>
  );
};

export default CreateFlowField;
