import { Button, Flex, Select, Text } from "@chakra-ui/react";

interface TablePaginationProps {
  currentPage: number;
  totalRows: number;
  currentPageRows: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
}

const TablePagination = ({
  rowsPerPage,
  onChangeRowsPerPage,
  currentPage,
  currentPageRows,
  totalRows,
  onPageChange,
}: TablePaginationProps) => {
  return (
    <Flex justifyContent="end" alignItems="center" mt={4}>
      {onChangeRowsPerPage && rowsPerPage && (
        <>
          <Text fontSize={{ base: "md", "2xl": "lg" }}>Rows per page: </Text>
          <Select
            mx={2}
            w={"75px"}
            variant={"unstyled"}
            value={rowsPerPage}
            onChange={(e) => onChangeRowsPerPage(Number(e.target.value))}
            fontSize={{ base: "md", "2xl": "lg" }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </Select>
        </>
      )}
      <Text fontSize={{ base: "md", "2xl": "lg" }} mr={4}>
        {(currentPage - 1) * rowsPerPage + 1}-
        {Math.min(
          currentPage * rowsPerPage,
          (currentPage - 1) * rowsPerPage + currentPageRows
        )}{" "}
        of {totalRows}
      </Text>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        variant={"ghost"}
      >
        <Text fontSize={{ base: "md", "2xl": "lg" }}>{"<"}</Text>
      </Button>
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage >= Math.ceil(totalRows / rowsPerPage)}
        variant={"ghost"}
      >
        <Text fontSize={{ base: "md", "2xl": "lg" }}>{">"}</Text>
      </Button>
    </Flex>
  );
};

export default TablePagination;
