import { DocumentDto, PropertyData } from "../types/patient";
import { Property } from "../types/tag";
import axiosAuth from "./axiosAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

export const uploadFile = async (file: File, patientId: string) => {
  const formData = new FormData();
  formData.append("files", file);
  return axiosAuth.post<DocumentDto>(
    `${API_URL}/docs/uploadFiles/${patientId}`,
    formData
  );
};

export const deleteFile = async (docId: string) => {
  return axiosAuth.delete(`${API_URL}/docs/${docId}`);
};

export const getDocById = async (docId: string) => {
  return axiosAuth.get<DocumentDto>(`${API_URL}/docs/${docId}`);
};

export const getDocUrlById = async (docId: string) => {
  return axiosAuth.get<string>(`${API_URL}/docs/url/${docId}`);
};

export const parseDocument = async (docId: string) => {
  return axiosAuth.get<Partial<Record<Property, PropertyData>>>(
    `${API_URL}/llm-chat/parse/${docId}`
  );
};
