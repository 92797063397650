export function wordify(input: string): string {
  // Check if the string is in snake_case
  if (input.includes("_")) {
    return input
      .split("_")
      .map((word) => word.toLowerCase())
      .join(" ");
  }

  // Check if the string is in camelCase
  if (/[a-z][A-Z]/.test(input)) {
    return input.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  }

  // Check if the string is in UPPER_CASE
  if (input === input.toUpperCase()) {
    return input.toLowerCase();
  }

  // If the string is in a different format, return it as is
  return input;
}

export function capitalize(input: string): string {
  if (input.length === 0) {
    return input; // Return empty string if input is empty
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function formatEnumValue(value: string): string {
  return value
    .toLowerCase() // Convert the entire string to lower case
    .split("_") // Split the string into an array at each underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the array elements into a single string with spaces
}
