import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface DeprecateFlowModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmDeprecate: () => void;
}

const DeprecateFlowModal = ({
  onConfirmDeprecate,
  isOpen,
  onClose,
}: DeprecateFlowModalProps) => {
  const handleDeprecate = () => {
    // Call your deprecation logic here
    onConfirmDeprecate();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deprecate Flow</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            This action cannot be undone. All existing responses can still be
            viewed.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeprecate}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeprecateFlowModal;
