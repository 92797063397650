import { RRule } from "rrule";
import { Property } from "./tag";

export enum WorkflowStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  DEPRECATED = "DEPRECATED",
}

export enum ProviderType {
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  FB_MESSENGER = "FB_MESSENGER",
}

export enum ResponseType {
  PROPERTY = "PROPERTY",
  SHORT_TEXT = "SHORT_TEXT",
  LONG_TEXT = "LONG_TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  DROPDOWN = "DROPDOWN",
}

export enum OptionsLayout {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export type WorkflowField = {
  key: string; // e.g. q_1, q_2
  label: string; // label for table, graph
  question: string; // question asked to user
  responseType: ResponseType;
  property?: Property;
  required?: boolean;
  numberOptions?: {
    // for number question
    min?: number;
    max?: number;
  };
  rangeLabels?: {
    // for radio question
    low?: string;
    high?: string;
  };
  // dictionary key string value string
  options?: Record<string, string>;
  layout?: OptionsLayout;
  isMultiSelect: boolean;
};

export enum WorkflowTemplateId {
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
  FITNESS_TRACKER = "FITNESS_TRACKER",
  CUSTOM = "CUSTOM",
}

export type WorkflowMetaData = {
  waTemplateId?: string;
};

export type CreateWorkflowDto = {
  channel: ProviderType;
  patientIds: string[];
  message: string;
  schedule: string;
  fields: WorkflowField[];
  metadata?: WorkflowMetaData;
  name: string;
  templateId?: WorkflowTemplateId;
  postSubmitMsg?: {
    header?: string;
    body?: string;
  };
};

export type WorkflowDto = CreateWorkflowDto & {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  status: WorkflowStatus;
};

export type WorkflowCardData = {
  workflowId: string;
  status: WorkflowStatus;
  title: string;
  frequency: RRule;
  numRespondents: number;
  numResponses: number;
};

export type WorkflowDataDto = {
  id: string;
  patientId: string;
  workflowId: string;
  rawData: Record<string, string>;
  createdAt: Date;
  updatedAt: Date;
  workflow?: WorkflowDto;
};

export type WorkflowDataResp = WorkflowDataDto & {
  patientName: string;
};

export type PatientMetricDto = {
  id: string;
  patientId: string;
  propertyId: string;
  value: number;
  flowId?: string;
  timestamp: string;
  unit?: string;
  category?: string;
};

export type WorkflowDataListReq = {
  patientIds?: string[];
  workflowIds?: string[];
};

export type CountResp = {
  workflowId: string;
  count: number;
};

export type RespondWorkflowDto = {
  patientId: string;
  workflowId: string;
  rawData: Record<string, string>;
  sendAcknowledge?: boolean;
};
