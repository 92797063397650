import {
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROUTES, theme } from "../../../constants";
import { TagDto } from "../../../types/tag";
import { TagPill } from "../TagPill";

interface TagsTableProps {
  tags: TagDto[];
  onDelete?: (tagId: string) => void;
}

export const TagsTable = ({ tags, onDelete }: TagsTableProps) => {
  const navigate = useNavigate();
  const hoverBackground = useColorModeValue(`${theme}.100`, `${theme}.700`);
  const viewTag = (tagId: string) => {
    navigate(`${ROUTES.TAGS}/${tagId}`);
  };

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Tag Name</Th>
          <Th>Description</Th>
          <Th>Last Updated By</Th>
          <Th>Clients Tagged</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {tags.map((tag) => (
          <Tr
            key={tag.id}
            onClick={() => viewTag(tag.id!)}
            _hover={{
              bg: hoverBackground,
              cursor: "pointer",
            }}
          >
            <Td>
              <TagPill tagColor={tag.tagColor} name={tag.name} />
            </Td>
            <Td>
              <Text noOfLines={2}>{tag.description}</Text>
            </Td>
            <Td>
              <Text noOfLines={2}>{tag.updatedBy}</Text>
            </Td>
            <Td>{tag.tagCount}</Td>
            <Td>
              <IconButton
                aria-label="View tag"
                variant={"ghost"}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${ROUTES.TAGS}/${tag.id}`);
                }}
                icon={<FaEye />}
              >
                View
              </IconButton>
              <IconButton
                aria-label="Edit tag"
                variant={"ghost"}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${ROUTES.TAGS}/manage/${tag.id}`);
                }}
                icon={<FaEdit />}
              >
                Edit
              </IconButton>
              {onDelete && (
                <IconButton
                  aria-label="Delete tag"
                  variant={"ghost"}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(tag.id!);
                  }}
                  icon={<FaTrash />}
                >
                  Delete
                </IconButton>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
