"use client";

import {
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../api/auth";
import { ROUTES } from "../../constants";

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const sendEmail = async () => {
    try {
      const resp = await forgotPassword(email);
      if (resp.status === HttpStatusCode.Created) {
        toast({
          title: "Password reset email sent",
          description: `If it exists, an email has been sent to ${email} containing a password reset link.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsEmailSent(true);
      }
    } catch (error: any) {
      toast({
        title: "An error occurred",
        description:
          error?.response?.data?.message ||
          "Unable to send password reset email",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={"gray.50"}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        {isEmailSent ? (
          <>
            <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
              Password reset email sent
            </Heading>
            <Text fontSize={{ base: "sm", sm: "md" }} color={"gray.800"}>
              If it exists, an email has been sent to {email} containing a
              password reset link.
            </Text>
            <Button onClick={() => navigate(ROUTES.LOGIN)} color={"white"}>
              Back to login
            </Button>
          </>
        ) : (
          <>
            <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
              Forgot your password?
            </Heading>
            <Text fontSize={{ base: "sm", sm: "md" }} color={"gray.800"}>
              You&apos;ll get an email with a reset link
            </Text>
            <FormControl id="email">
              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="your-email@example.com"
                _placeholder={{ color: "gray.500" }}
                type="email"
              />
            </FormControl>
            <Stack spacing={6}>
              <Button onClick={sendEmail} color={"white"}>
                Request Reset
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Flex>
  );
};

export default ForgotPasswordPage;
