import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useToast,
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { FiFile, FiX } from "react-icons/fi";
import { uploadFile } from "../../../api/document";

interface UploadModalProps {
  isReportOpen: boolean;
  onOpenReport: () => void;
  onCloseReport: () => void;
  patientId: string;
  onSubmit: () => void;
}

function UploadModal({
  isReportOpen,
  onOpenReport,
  onCloseReport,
  patientId,
  onSubmit,
}: UploadModalProps) {
  const [file, setFile] = useState<File | null>(null);
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
    } else {
      toast({
        title: "Invalid file type",
        description: "Please upload a PDF file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setFile(null); // Clear the file if it's not a PDF
    }
  };

  const handleFileInputClick = () => {
    inputRef.current?.click();
  };

  const handleClearFile = () => {
    setFile(null); // Clear the selected file
    inputRef.current!.value = ""; // Reset the input element
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      console.log("File to be handled:", file);

      try {
        // Process the file upload here or pass the file to an API
        await uploadFile(file, patientId);
        handleClearFile(); // Clear the file after submitting
        onCloseReport(); // Close the modal after submitting
        onSubmit();
      } catch (error) {
        console.error("Error uploading file:", error);
        toast({
          title: "File upload failed",
          description: "An error occurred while uploading the file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <Modal isOpen={isReportOpen} onClose={onCloseReport}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload PDF Report</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl isRequired>
                <FormLabel htmlFor="file">PDF file</FormLabel>
                <InputGroup>
                  <Input
                    placeholder="Click to upload PDF file"
                    readOnly
                    value={file ? file.name : ""}
                    onClick={handleFileInputClick}
                  />
                  <InputRightElement width="4.5rem">
                    {file ? (
                      <IconButton
                        aria-label="Clear file"
                        icon={<FiX />}
                        size="sm"
                        onClick={handleClearFile}
                        mr={2}
                      />
                    ) : (
                      <Icon as={FiFile} />
                    )}
                  </InputRightElement>
                  <input
                    ref={inputRef}
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </InputGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onCloseReport}>
                Close
              </Button>
              <Button type="submit" colorScheme="teal">
                Submit
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UploadModal;
