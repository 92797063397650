import { Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { OptionsLayout, WorkflowField } from "../../types/workflow";

interface RadioFormFieldProps {
  field: WorkflowField;
  handleInputChange: (key: string, value: string) => void;
}
const RadioFormField = ({ field, handleInputChange }: RadioFormFieldProps) => {
  return (
    <RadioGroup onChange={(value) => handleInputChange(field.key, value)}>
      <Stack
        direction={field.layout === OptionsLayout.HORIZONTAL ? "row" : "column"}
        align="left"
        spacing={2}
      >
        <Text>{field.rangeLabels?.low}</Text>
        {field.options &&
          Object.entries(field.options).map((radioOption, i) => {
            return (
              <Stack
                key={`${field.key}-radio-option-${i}`}
                direction={
                  field.layout === OptionsLayout.HORIZONTAL ? "column" : "row"
                }
              >
                <Radio id={`radio-${i + 1}`} value={radioOption[0]}></Radio>
                <Text>{radioOption[1]}</Text>
              </Stack>
            );
          })}
        <Text>{field.rangeLabels?.high}</Text>
      </Stack>
    </RadioGroup>
  );
};

export default RadioFormField;
