import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  GridItem,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChartData } from "chart.js";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientMetricList } from "../../api/patientMetric";
import { getPatientById } from "../../api/patients";
import { getWorkflowsByPatientId } from "../../api/workflows";
import Header from "../../components/Head"; // Update path to Header component
import Activity from "../../components/Patients/Activity";
import LineChartComponent from "../../components/Patients/LineChartComponent";
import PatientInfoSearch from "../../components/Patients/PatientInfoSearch/PatientInfoSearch";
import SidePatientDetail from "../../components/Patients/SidePatientDetail";
import { TagPill } from "../../components/Tags/TagPill";
import { ROUTES } from "../../constants";
import { propertyUnits } from "../../constants/properties";
import { getReadableRRule } from "../../constants/time";
import { PatientDto } from "../../types/patient";
import { Property } from "../../types/tag";
import { PatientMetricDto, WorkflowDto } from "../../types/workflow";
import { getStatusColor, toTitleCase } from "../../utils";
import { formatDate } from "../../utils/date";
import { parseISO } from "date-fns";

export default function ViewPatientInfoPage() {
  const [patient, setPatient] = useState<PatientDto>();
  const [metrics, setMetrics] = useState<PatientMetricDto[][]>([]);

  const [workflows, setWorkflows] = useState<WorkflowDto[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { patientId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!patientId) {
      return;
    }
    fetchPatientById(patientId);
    fetchPatientWorkflows(patientId);

    const getPatientMetrics = async () => {
      const metrics = await getPatientMetricList({ patientIds: [patientId] });
      if (!metrics) return;

      const groupedMetrics: Record<string, PatientMetricDto[]> = {};
      metrics.forEach((metric) => {
        const timestamp = parseInt(metric.timestamp);
        if (!groupedMetrics[timestamp]) {
          groupedMetrics[timestamp] = [];
        }
        groupedMetrics[timestamp].push(metric);
      });

      setMetrics(
        Object.entries(groupedMetrics)
          .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
          .map(([, value]) => value)
      );
    };

    getPatientMetrics();
  }, [patientId]);

  const fetchPatientById = async (patientId: string): Promise<boolean> => {
    try {
      if (!patientId) {
        return false;
      }
      const resp = await getPatientById(patientId);
      const patient = resp.data;
      if (patient) {
        setPatient({
          ...patient,
          dob:
            typeof patient.dob === "string"
              ? parseISO(patient.dob)
              : patient.dob,
        });
        return true;
      }
    } catch (error) {
      console.error("Error fetching patient", error);
    } finally {
      return false;
    }
  };

  const fetchPatientWorkflows = async (patientId: string) => {
    const workflowsResp = await getWorkflowsByPatientId(patientId);
    if (workflowsResp) {
      setWorkflows(workflowsResp);
    }
  };
  if (!patient) {
    return <div>Loading...</div>;
  }

  const getGraphData = (properties: string[], idx: number): ChartData => {
    if (metrics.length === 0) return { labels: [], datasets: [] };

    const filtered: PatientMetricDto[][] = [];
    metrics.forEach((m) => {
      const filteredMetrics = m.filter((d) =>
        properties.includes(d.propertyId)
      );
      if (filteredMetrics.length > 0) {
        filtered.push(filteredMetrics);
      }
    });
    if (filtered.length === 0) return { labels: [], datasets: [] };
    return {
      labels: filtered.map((m) => {
        const date = new Date(parseInt(m[0].timestamp));
        const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits for day
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits for month

        return `${day}/${month}`;
      }),
      datasets: properties.map((property, i) => {
        return {
          label: toTitleCase(property),
          data: filtered.map((m) => {
            const metric = m.find((d) => d.propertyId === property);
            return metric?.value ?? null;
          }),
          fill: false,
          borderColor: `rgb(${idx + i * 200}, 99, 132)`,
          tension: 0.1,
        };
      }),
    };
  };

  const getLatestMetric = (property: string) => {
    // iterate reverse to find the latest metric
    for (let i = metrics.length - 1; i >= 0; i--) {
      const metric = metrics[i].find((m) => m.propertyId === property);
      if (metric) return metric;
    }
  };

  const bpSysLatest = getLatestMetric(
    Property.BLOOD_PRESSURE_SYSTOLIC.toString()
  );

  const bpDiaLatest = getLatestMetric(
    Property.BLOOD_PRESSURE_DIASTOLIC.toString()
  );

  const handleSearch = (search: string) => {
    if (!search || search === "") {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };

  return (
    <>
      <Header description="View and manage your clients" />
      <HStack align={"start"}>
        <SidePatientDetail patient={patient} />
        <VStack pl={8} w={"full"} minW={600} align={"left"}>
          <PatientInfoSearch patient={patient} onSearch={handleSearch} />
          {!showSearch && (
            <>
              <Divider my={4} />
              <Tabs variant="enclosed" w={"full"}>
                <TabList>
                  <Tab>Overview</Tab>
                  <Tab>Repository</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Card p={4}>
                      <Text fontSize={"lg"} fontWeight={"bold"}>
                        Overview
                      </Text>
                      <HStack flexWrap={"wrap"} mt={4} spacing={8}>
                        <VStack align={"left"}>
                          <Text
                            textTransform={"uppercase"}
                            fontSize={"sm"}
                            fontWeight={"300"}
                          >
                            Last Activity
                          </Text>
                          <Text>
                            {metrics.length > 0
                              ? formatDate(
                                  metrics[metrics.length - 1][0].timestamp
                                )
                              : "No activity yet"}
                          </Text>
                        </VStack>
                        <VStack align={"left"}>
                          <Text
                            textTransform={"uppercase"}
                            fontSize={"sm"}
                            fontWeight={"300"}
                          >
                            Tags
                          </Text>
                          <Flex
                            justifyContent={"space-between"}
                            grow={1}
                            w={"100%"}
                          >
                            {patient.tags!.map((tag) => (
                              <Box mr={2}>
                                <TagPill
                                  key={tag.id}
                                  tagColor={tag.tagColor}
                                  name={tag.name}
                                />
                              </Box>
                            ))}
                          </Flex>
                        </VStack>
                        <Spacer />
                        <Button
                          variant={"ghost"}
                          onClick={() => navigate(`${ROUTES.TAGS}/manage/new`)}
                        >
                          MANAGE TAGS
                        </Button>
                      </HStack>
                    </Card>
                    <Card p={4} mt={4}>
                      <Text mb={2} fontSize={"lg"} fontWeight={"bold"}>
                        Health Data Trackers
                      </Text>
                      {metrics.length > 0 && (
                        <Flex wrap={"wrap"}>
                          {bpSysLatest && bpDiaLatest && (
                            <Box m={8} w={400}>
                              <Heading fontSize="sm">Blood Pressure</Heading>
                              <Text fontSize="sm">
                                {bpSysLatest?.value ?? "-"}/
                                {bpDiaLatest?.value ?? "-"} mmHg
                              </Text>
                              <Text fontSize="sm" mb={4}>
                                Last recorded:{" "}
                                {formatDate(bpSysLatest?.timestamp ?? "")}
                              </Text>
                              <LineChartComponent
                                data={getGraphData(
                                  [
                                    Property.BLOOD_PRESSURE_SYSTOLIC,
                                    Property.BLOOD_PRESSURE_DIASTOLIC,
                                  ],
                                  0
                                )}
                              />
                            </Box>
                          )}
                          {Object.keys(Property)
                            .filter(
                              (p) =>
                                !(
                                  p === Property.BLOOD_PRESSURE_DIASTOLIC ||
                                  p === Property.BLOOD_PRESSURE_SYSTOLIC
                                )
                            )
                            .map((key, idx) => {
                              const metric = getLatestMetric(key);
                              const graphData = getGraphData([key], idx);
                              if (
                                !metric ||
                                (graphData.datasets[0] &&
                                  graphData.datasets[0].data.filter((d) => d)
                                    .length === 0)
                              ) {
                                return null;
                              }
                              return (
                                <Box m={8} w={400}>
                                  <Heading fontSize="sm">
                                    {toTitleCase(metric?.propertyId)}
                                  </Heading>
                                  <Text fontSize="sm">
                                    {metric?.value ?? "-"}{" "}
                                    {metric.unit ? metric.unit : ""}
                                    {!metric.unit && metric?.propertyId
                                      ? propertyUnits[
                                          metric.propertyId as keyof typeof Property
                                        ]
                                      : ""}
                                  </Text>
                                  <Text fontSize="sm" mb={4}>
                                    Last recorded:{" "}
                                    {formatDate(metric?.timestamp ?? "")}
                                  </Text>
                                  <LineChartComponent data={graphData} />
                                </Box>
                              );
                            })}
                        </Flex>
                      )}
                      {metrics.length === 0 && <Text>No metrics yet!</Text>}
                    </Card>

                    <Card p={4} mt={4}>
                      <Text mb={2} fontSize={"lg"} fontWeight={"bold"}>
                        Flows
                      </Text>
                      <SimpleGrid columns={2} spacing={10}>
                        {workflows.map((flow) => (
                          <GridItem w="100%" key={flow.id}>
                            <Card
                              _hover={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(
                                  `${ROUTES.CLIENTS}/${patient.id}/flow/${flow.id}`
                                )
                              }
                            >
                              <CardHeader>
                                <Tag
                                  variant="solid"
                                  colorScheme={getStatusColor(flow.status)}
                                  maxW={"fit-content"}
                                  textTransform={"capitalize"}
                                >
                                  {flow.status.toLowerCase()}
                                </Tag>
                                <Text mt={2} fontSize="xl" fontWeight={"bold"}>
                                  {flow.name}
                                </Text>
                              </CardHeader>
                              <CardBody mt={-4}>
                                <Text color="gray">
                                  {getReadableRRule(flow.schedule)}
                                </Text>
                              </CardBody>
                            </Card>
                          </GridItem>
                        ))}
                      </SimpleGrid>

                      {workflows.length === 0 && (
                        <Text>
                          {patient?.name} currently doesn't have any flows.
                        </Text>
                      )}
                    </Card>
                  </TabPanel>
                  <TabPanel>
                    <Activity
                      patient={patient}
                      refetchPatient={() => fetchPatientById(patientId!)}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
        </VStack>
      </HStack>
    </>
  );
}
