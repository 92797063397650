import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { createTag, getTagById, updateTag } from "../../api/tag";
import { ROUTES } from "../../constants";
import { ConditionDto, Operation, Property, TagDto } from "../../types/tag";
import { formatEnumValue } from "../../utils/strings";

export const ManageTagsPage = () => {
  const navigate = useNavigate();
  const { tagId } = useParams();
  const isEditing = tagId !== "new";
  const pageTitle = isEditing ? "Manage Tag" : "Create Tag";
  const toast = useToast();

  // Initial state for tag details
  const [tag, setTag] = useState<TagDto>({
    name: "",
    tagColor: "",
    description: "",
    removeWhenNotMet: false,
    tagRules: [
      {
        conditions: {
          type: "AND",
          conditions: [
            {
              property: "",
              operation: Operation.GT,
              value: "",
            } as ConditionDto,
          ],
        },
      },
    ],
  });

  // Handle input changes for non-nested fields
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setTag((prevDetails) => ({
      ...prevDetails,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    if (isEditing && tagId) {
      fetchTag(tagId);
    }
  }, [isEditing, tagId]);

  const fetchTag = async (tagId: string) => {
    const resp = await getTagById(tagId);
    if (resp.data) {
      setTag(resp.data);
    }
  };

  // Add a new condition to the tag logic
  const addCondition = () => {
    const newCondition = {
      property: "",
      operation: Operation.GT,
      value: "",
    };
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: [
              ...prevDetails.tagRules[0].conditions.conditions,
              newCondition,
            ],
          },
        },
      ],
    }));
  };

  // Handle input changes for dynamic conditions
  const handleConditionChange = (
    index: number,
    field: string,
    value: string
  ) => {
    const newConditions = [...tag.tagRules[0].conditions.conditions];
    newConditions[index] = { ...newConditions[index], [field]: value };
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: newConditions,
          },
        },
      ],
    }));
  };

  // Remove a condition from the tag logic
  const removeCondition = (index: number) => {
    const newConditions = [...tag.tagRules[0].conditions.conditions];
    newConditions.splice(index, 1);
    setTag((prevDetails) => ({
      ...prevDetails,
      tagRules: [
        {
          ...prevDetails.tagRules[0],
          conditions: {
            ...prevDetails.tagRules[0].conditions,
            conditions: newConditions,
          },
        },
      ],
    }));
  };

  const handleSave = async () => {
    try {
      if (isEditing) {
        await updateTag(tag.id!, tag);
      } else {
        await createTag(tag);
      }
      navigate(ROUTES.TAGS);
    } catch (e) {
      toast({
        title: "Error",
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  };

  return (
    <Box p={5}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <HStack
          cursor={"pointer"}
          alignItems={"center"}
          color={"gray"}
          onClick={() => navigate(ROUTES.TAGS)}
        >
          <FaChevronLeft />
          <Text>Back to tags</Text>
        </HStack>
        <Heading my={4}>{pageTitle}</Heading>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Tag Name"
              name="name"
              value={tag.name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Tag Color</FormLabel>
            <Select
              placeholder="Select option"
              name="tagColor"
              value={tag.tagColor}
              onChange={handleChange}
            >
              <option value="grey">Grey</option>
              <option value="red">Red</option>
              <option value="orange">Orange</option>
              <option value="green">Green</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Description"
              name="description"
              value={tag.description}
              onChange={handleChange}
            />
          </FormControl>

          <Heading size="md" my={4}>
            Define Tag Logic
          </Heading>

          {/* Dynamic conditions */}
          {tag.tagRules[0].conditions.conditions.map((condition, index) => (
            <HStack key={index}>
              {index === 0 ? (
                <Text w={200}>When</Text>
              ) : (
                <Text w={200}>And</Text>
              )}
              <Select
                placeholder="Property"
                required
                value={condition.property}
                onChange={(e) =>
                  handleConditionChange(index, "property", e.target.value)
                }
              >
                {Object.keys(Property)
                  .sort()
                  .map((key: string) => (
                    <option value={Property[key as keyof typeof Property]}>
                      {formatEnumValue(Property[key as keyof typeof Property])}
                    </option>
                  ))}
              </Select>

              <Select
                required
                value={condition.operation}
                onChange={(e) =>
                  handleConditionChange(index, "operation", e.target.value)
                }
              >
                {/* Hardcoded operation options based on the Operation enum */}
                <option value={Operation.LT}>Is Less Than</option>
                <option value={Operation.LTE}>Is Less Than or Equal To</option>
                <option value={Operation.EQ}>Is Equal To</option>
                <option value={Operation.GTE}>
                  Is Greater Than or Equal To
                </option>
                <option value={Operation.GT}>Is Greater Than</option>
              </Select>

              <Input
                required
                placeholder="Value"
                value={condition.value}
                onChange={(e) =>
                  handleConditionChange(index, "value", e.target.value)
                }
              />

              <IconButton
                colorScheme="gray"
                onClick={() => removeCondition(index)}
                icon={<FaTrash />}
                aria-label={"delete"}
                isDisabled={index === 0}
              />
            </HStack>
          ))}
          <Button
            onClick={addCondition}
            leftIcon={<FaPlus />}
            variant={"ghost"}
            w={150}
          >
            Add Condition
          </Button>

          <Checkbox
            my={5}
            isChecked={tag.removeWhenNotMet}
            name="removeWhenNotMet"
            onChange={handleChange}
          >
            Remove tag when conditions are no longer met
          </Checkbox>
          <Flex justify="flex-end">
            <Button colorScheme="blue" mt={4} type="submit">
              Save And Continue
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};
