import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ROUTES, theme } from "../../../constants";
import { PatientTagDto } from "../../../types/tag";
import { calculateAge, formatDateToReadableString } from "../../../utils/date";

interface PatientTagsTableProps {
  patientTags: PatientTagDto[];
}

export const PatientTagsTable = ({ patientTags }: PatientTagsTableProps) => {
  const navigate = useNavigate();
  const hoverBackground = useColorModeValue(`${theme}.100`, `${theme}.700`);
  const viewPatient = (id: string) => {
    navigate(`${ROUTES.CLIENTS}/${id}`);
  };

  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Age</Th>
          <Th>Sex</Th>
          <Th>Tagged At</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {patientTags.map((patientTag, index) => (
          <Tr
            key={index}
            onClick={() => viewPatient(patientTag.patientId!)}
            _hover={{
              bg: hoverBackground,
              cursor: "pointer",
            }}
          >
            <Td>{patientTag.patient?.name}</Td>
            <Td>{calculateAge(patientTag.patient?.dob!)}</Td>
            <Td>{patientTag.patient?.sex}</Td>
            <Td>{formatDateToReadableString(patientTag.createdAt!)}</Td>
            <Td>
              {formatDateToReadableString(patientTag.patient?.lastActivity!)}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
