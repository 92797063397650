import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteTag, getAllTags } from "../../api/tag";
import Header from "../../components/Head";
import TablePagination from "../../components/TablePagination";
import { TagsTable } from "../../components/Tags/TagsTable";
import { ROUTES } from "../../constants";
import { TagDto } from "../../types/tag";

export default function ViewTagsPage() {
  const navigate = useNavigate();

  const [tags, setTags] = useState<TagDto[]>([]);
  const [filter, setFilter] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTags, setTotalTags] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    fetchAllTags();
  }, [currentPage, itemsPerPage]);

  const fetchAllTags = async () => {
    const resp = await getAllTags(currentPage, itemsPerPage);
    if (resp.data) {
      setTags(resp.data.data);
      setTotalTags(resp.data.total);
    }
  };

  const handleDelete = async (tagId: string) => {
    const resp = await deleteTag(tagId);
    if (resp.status === 200) {
      fetchAllTags();
    }
  };

  return (
    <>
      <Header description="Manage tags" />
      <Container maxW={"5xl"}>
        <Stack>
          <Flex justifyContent={"space-between"}>
            <VStack align={"left"}>
              <Heading>Tags</Heading>
              <Text maxW="80%">
                Surface the most important client information through tags.
                Define logic that will determine when Speedback automatically
                applies tags to clients and removes them. <br /> For example, a
                client may be tagged as “Pre-diabetes” or “Needs follow-up”.
              </Text>
            </VStack>
            <Button
              minW={100}
              onClick={() => {
                navigate(`${ROUTES.TAGS}/manage/new`);
              }}
            >
              Create Tag
            </Button>
          </Flex>

          <Box mt={6}>
            <TagsTable tags={tags} onDelete={handleDelete} />
            <TablePagination
              currentPage={currentPage}
              totalRows={totalTags}
              currentPageRows={tags.length}
              onPageChange={setCurrentPage}
              rowsPerPage={itemsPerPage}
              onChangeRowsPerPage={setItemsPerPage}
            />
          </Box>
        </Stack>
      </Container>
    </>
  );
}
