import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PatientActivity } from "../../../types/patient";

interface NotesSectionProps {
  header: string;
  activities: PatientActivity[];
}
const NotesSection = ({ header, activities }: NotesSectionProps) => {
  const formatDate = (date: Date): string => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
      timeZoneName: "short",
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  return (
    <VStack align={"left"} my={4}>
      <Text fontSize="lg" fontWeight="bold">
        {header}
      </Text>
      {activities.map((a) => (
        <Card>
          <Accordion allowToggle>
            <AccordionItem>
              {({ isExpanded }: any) => (
                <>
                  <AccordionButton>
                    <CardHeader w="full">
                      <HStack w="full">
                        <AccordionIcon />
                        <HStack w="full" justifyContent={"space-between"}>
                          <Box>{a.header}</Box>
                          {a.date && (
                            <Text fontSize={"sm"} fontWeight={"normal"}>
                              {formatDate(new Date(a.date))}
                            </Text>
                          )}
                        </HStack>
                      </HStack>
                      {!isExpanded && (
                        <Flex
                          mt={2}
                          textAlign={"left"}
                          noOfLines={3}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {a.preview}
                        </Flex>
                      )}
                    </CardHeader>
                  </AccordionButton>
                  <AccordionPanel>
                    <CardBody>{a.content}</CardBody>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Card>
      ))}
    </VStack>
  );
};

export default NotesSection;
