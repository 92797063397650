import {
  Box,
  Button,
  Card,
  ChakraProvider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import { useLocation, useNavigate } from "react-router-dom";
import { createWorkflow } from "../../api/workflows";
import CreateFlowField from "../../components/Flows/CreateFlowField";
import { ROUTES, theme } from "../../constants";
import {
  CreateWorkflowDto,
  ProviderType,
  ResponseType,
  WorkflowField,
} from "../../types/workflow";

const CreateFlowPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const toast = useToast();
  const [workflowData, setWorkflowData] = useState<CreateWorkflowDto>();

  useEffect(() => {
    const createWorkflowDto: CreateWorkflowDto = state.createWorkflowDto;
    setWorkflowData(createWorkflowDto);
  }, []);

  const isValidField = (
    field: WorkflowField
  ): { success: boolean; errorMsg: string } => {
    if (field.responseType === ResponseType.RADIO) {
      if (field.options && Object.keys(field.options).length === 0) {
        return { success: false, errorMsg: "Radio options are empty." };
      }
    }

    if (field.responseType === ResponseType.NUMBER) {
      if (isNumberRangeInvalid(field)) {
        return { success: false, errorMsg: "Invalid min/max values." };
      }
    }

    return { success: true, errorMsg: "" };
  };

  const isNumberRangeInvalid = (field: WorkflowField) => {
    if (field.responseType !== ResponseType.NUMBER) {
      return;
    }

    if (field.numberOptions?.max && field.numberOptions?.min) {
      return field.numberOptions.max < field.numberOptions.min;
    }

    return false;
  };

  const publishFlow = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!workflowData) {
      toast({
        title: "Failed to Publish Flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    for (const field of workflowData.fields) {
      const { success, errorMsg } = isValidField(field);
      if (!success) {
        toast({
          title: "Failed to Publish Flow",
          description: errorMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    const resp = await createWorkflow(workflowData);
    if (!resp) {
      toast({
        title: "Failed to Publish Flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    navigate(ROUTES.FLOWS_LIST);
  };

  if (!workflowData) {
    navigate(ROUTES.SELECT_TEMPLATE);
    return <></>;
  }

  const updateField = (index: number, field: WorkflowField) => {
    const newFields = [...workflowData.fields];
    newFields[index] = field;
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  const addField = () => {
    const newFields = [...workflowData.fields];
    newFields.push({
      label: "",
      question: "",
      responseType: ResponseType.RADIO,
      required: false,
      isMultiSelect: false,
      key: `q_${newFields.length + 1}`,
    });
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  const removeField = (index: number) => {
    const newFields = [...workflowData.fields];
    newFields.splice(index, 1);
    setWorkflowData({ ...workflowData, fields: newFields });
  };

  return (
    <ChakraProvider>
      <Box
        display={"flex"}
        flexDirection={"column"}
        as="form"
        onSubmit={publishFlow}
        maxW={"100%"}
        minH={`calc(100vh - ${200}px)`}
      >
        <Card width="full" p={6}>
          <FormControl isRequired>
            <FormLabel color={`${theme}.500`}>
              <Heading
                size={"md"}
                display={"inline"}
                textTransform={"capitalize"}
              >
                Flow Name
              </Heading>
            </FormLabel>
            <Input
              fontSize={"2xl"}
              fontWeight={"bold"}
              value={workflowData.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setWorkflowData({ ...workflowData, name: e.target.value })
              }
            />
          </FormControl>
        </Card>

        <VStack spacing={5} align={"start"}>
          <Card width="full" p={6}>
            <FormControl isRequired>
              <FormLabel color={`${theme}.500`}>
                <Heading
                  size={"md"}
                  display={"inline"}
                  textTransform={"capitalize"}
                >
                  Initial message to patients
                </Heading>
              </FormLabel>
              <Box border={"1px solid"} borderColor={"gray.200"} p={4}>
                <HighlightWithinTextarea
                  highlight={"${name}"}
                  value={workflowData?.message}
                  onChange={(newValue: string) =>
                    setWorkflowData({ ...workflowData, message: newValue })
                  }
                />
              </Box>
            </FormControl>
            {workflowData.channel === ProviderType.WHATSAPP && (
              <Text mt={2}>Thanks and have a nice day!</Text>
            )}
          </Card>

          {workflowData.fields.map((field, index) => (
            <CreateFlowField
              field={field}
              index={index}
              removeField={() => removeField(index)}
              updateField={(f) => updateField(index, f)}
            />
          ))}
          <Button w="full" onClick={addField}>
            Add new field
          </Button>

          <Card width="full" p={6}>
            <FormControl isRequired>
              <FormLabel color={`${theme}.500`}>
                <Heading
                  size={"md"}
                  display={"inline"}
                  textTransform={"capitalize"}
                >
                  Message after submitting
                </Heading>
              </FormLabel>
              <Input
                placeholder="Header"
                mb={2}
                fontSize={"2xl"}
                fontWeight={"bold"}
                value={workflowData.postSubmitMsg?.header}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setWorkflowData({
                    ...workflowData,
                    postSubmitMsg: {
                      ...workflowData.postSubmitMsg,
                      header: e.target.value,
                    },
                  })
                }
              />
              <Textarea
                placeholder="Add message for your patients to see after submitting form..."
                borderColor={"gray.200"}
                value={workflowData?.postSubmitMsg?.body}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setWorkflowData({
                    ...workflowData,
                    postSubmitMsg: {
                      ...workflowData.postSubmitMsg,
                      body: e.target.value,
                    },
                  })
                }
              />
            </FormControl>
          </Card>

          <Button w="full" type="submit" colorScheme={theme}>
            Publish Flow!
          </Button>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default CreateFlowPage;
