import {
  Button,
  Flex,
  FlexProps,
  HStack,
  Hide,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { useStaffStore } from "../../store/staffStore";
import { SIDEBAR_TRANSITION_VIEWPORT_WIDTH } from "./const";

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const logout = useStaffStore((state) => state.logout);

  const handleSignOut = () => {
    logout();
  };

  return (
    <Hide above={SIDEBAR_TRANSITION_VIEWPORT_WIDTH}>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="10"
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <HStack spacing={{ base: "2", md: "2" }}>
          {/* <ColorModeSwitcher /> */}
          <Flex alignItems={"center"}>
            <Button onClick={handleSignOut}>Sign out</Button>
          </Flex>
        </HStack>
      </Flex>
    </Hide>
  );
};

export default MobileNav;
