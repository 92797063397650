import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/auth";
import { ROUTES } from "../../constants";
import { StaffState, useStaffStore } from "../../store/staffStore";

const LoginPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const loginHook = useStaffStore((state: StaffState) => state.login);
  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (email === "" || password === "") {
      toast({
        title: "Please fill in all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const resp = await login(email, password);
      loginHook(resp.data);
      toast({
        title: "Logged in successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate(ROUTES.CLIENTS);
    } catch (error: any) {
      if (error.response.status === HttpStatusCode.Unauthorized) {
        const errorType = error.response.data.error;
        if (errorType == 'MissingDetails') {
          toast({
            title: "Your account is not linked to an organization. Contact us for help!",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Invalid email or password",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "An error occurred",
          description: error?.response?.data?.message || "Unable to log in",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Log in to your account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Link
                  onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
                  color={"blue.400"}
                >
                  Forgot password?
                </Link>
              </Stack>
              <Button onClick={handleLogin} color={"white"}>
                Sign in
              </Button>
              <Text align={"center"}>
                Don't have an account?{" "}
                <Link
                  href="mailto:speedback@equity.tech"
                  color={"blue.400"}
                >
                  Contact us
                </Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginPage;
