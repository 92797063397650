import {
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaChevronLeft, FaDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { rrulestr } from "rrule";
import { getByPatientIds } from "../../api/patients";
import { getWorkflowDataList } from "../../api/workflowData";
import {
  deprecateWorkflowById,
  getWorkflowById,
  sendFlow,
} from "../../api/workflows";
import DeprecateFlowModal from "../../components/Flows/DeprecateModal";
import TestFlowModal from "../../components/Flows/TestFlowModal";
import Header from "../../components/Head";
import { ROUTES, theme } from "../../constants";
import { getReadableRRule } from "../../constants/time";
import { PatientDto } from "../../types/patient";
import {
  ResponseType,
  WorkflowDataResp,
  WorkflowDto,
  WorkflowStatus,
} from "../../types/workflow";
import {
  exportToCSV,
  getChannelColor,
  getProviderFriendlyName,
} from "../../utils";

const FlowResponsesPage = () => {
  const { flowId } = useParams();
  const {
    isOpen: isDeprecateModalOpen,
    onOpen: openDeprecateModal,
    onClose: onCloseDeprecateFlowModal,
  } = useDisclosure();
  const {
    isOpen: isTestFlowModalOpen,
    onOpen: openTestFlowModal,
    onClose: onCloseTestFlowModal,
  } = useDisclosure();
  const [responses, setResponses] = useState<WorkflowDataResp[]>([]);
  const [workflow, setWorkflow] = useState<WorkflowDto>();
  const [patients, setPatients] = useState<PatientDto[]>([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [patientIdToSend, setPatientIdToSend] = useState<string>("");
  const [patientNameToSend, setPatientNameToSend] = useState<string>("");
  const [isRefreshed, setIsRefreshed] = useState(false);

  // pagination
  const hoverBackground = useColorModeValue(`${theme}.100`, `${theme}.700`);

  // on top of the normal columns, we have 3 additional columns (#, patient name and date recorded)
  const NUM_ADDITIONAL_COLS = 3;

  useEffect(() => {
    if (isRefreshed) {
      return;
    }

    async function getPageData() {
      if (!flowId) {
        return;
      }

      const workflow = await getWorkflowById(flowId);
      if (!workflow) {
        return;
      }

      setWorkflow(workflow);

      const patientsResp = await getByPatientIds(workflow.patientIds);
      if (!patientsResp.data) {
        return;
      }
      setPatients(patientsResp.data);

      const responses = await getWorkflowDataList({
        workflowIds: [flowId],
      });
      if (!responses) {
        return;
      }

      setResponses(responses);
    }

    getPageData();

    setIsRefreshed(true);
  }, [isRefreshed]);

  if (!flowId) {
    navigate(ROUTES.NOT_FOUND);
    return <></>;
  }

  if (!workflow) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const deprecateFlow = async () => {
    const resp = await deprecateWorkflowById(flowId);
    if (resp) {
      setIsRefreshed(false);
      toast({
        title: "Successfully deprecated flow",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate(ROUTES.FLOWS_LIST);
    } else {
      toast({
        title: "Failed to deprecate flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendFlow = async ({
    patientId,
    patientName,
  }: {
    patientId: string;
    patientName: string;
  }) => {
    try {
      await sendFlow({
        patientId,
        workflowId: flowId,
      });
      toast({
        title: `Successfully sent flow to ${patientName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: error?.response?.data?.message ?? "Failed to send flow",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseTestFlowModal = () => {
    onCloseTestFlowModal();
    setPatientIdToSend("");
  };

  const onClickSendFlow = async ({
    patientId,
    patientName,
  }: {
    patientId: string;
    patientName: string;
  }) => {
    await setPatientIdToSend(patientId);
    await setPatientNameToSend(patientName);
    openTestFlowModal();
  };

  const handleExportToCSV = () => {
    const headers = [
      "#",
      "Client Name",
      ...(workflow?.fields.map((field) => field.label) ?? []),
      "Date Recorded",
    ];

    const dataRows = responses.map((response, idx) => [
      `${responses.length - idx}`,
      response.patientName,
      ...workflow?.fields.map(
        (field, fieldIdx) => response.rawData[`q_${fieldIdx + 1}`] ?? ""
      ),
      new Date(response.createdAt).toLocaleDateString("en-GB"),
    ]);

    exportToCSV(dataRows, headers, `${workflow?.name}_export` ?? "export_data");
  };

  return (
    <>
      <Container maxW={"5xl"} minH={`calc(100vh - ${200}px)`}>
        <DeprecateFlowModal
          isOpen={isDeprecateModalOpen}
          onClose={onCloseDeprecateFlowModal}
          onConfirmDeprecate={deprecateFlow}
        />
        <TestFlowModal
          isOpen={isTestFlowModalOpen}
          onClose={handleCloseTestFlowModal}
          onConfirmTest={() =>
            handleSendFlow({
              patientId: patientIdToSend,
              patientName: patientNameToSend,
            })
          }
          patientId={patientIdToSend}
          patientName={patientNameToSend}
        />
        <Header description="Responses Page" />
        <HStack justifyContent={"space-between"}>
          <VStack align={"left"} spacing={3} mb={5}>
            <HStack
              cursor={"pointer"}
              alignItems={"center"}
              color={"gray"}
              onClick={() => navigate(ROUTES.FLOWS_LIST)}
            >
              <FaChevronLeft />
              <Text>Back to all flows</Text>
            </HStack>
            <Heading>{workflow?.name}</Heading>

            <HStack spacing={5} mb={5}>
              <Tag
                textTransform={"capitalize"}
                variant="solid"
                colorScheme={
                  workflow?.status === WorkflowStatus.PUBLISHED
                    ? "green"
                    : "red"
                }
                maxW={"fit-content"}
              >
                {workflow?.status.toLowerCase()}
              </Tag>

              <Tag
                textTransform={"capitalize"}
                variant="solid"
                colorScheme={getChannelColor(workflow.channel)}
                maxW={"fit-content"}
              >
                {getProviderFriendlyName(workflow?.channel)}
              </Tag>
              <Text color={"gray"}>{responses.length} responses</Text>
              {workflow && (
                <Text color={"gray"}>
                  {getReadableRRule(rrulestr(workflow.schedule).toString())}
                </Text>
              )}
            </HStack>
          </VStack>
          <HStack>
            <Button onClick={openDeprecateModal} colorScheme={"red"}>
              Deprecate Flow
            </Button>
          </HStack>
        </HStack>
        <VStack align={"left"} spacing={5}>
          <Heading fontWeight={"normal"} size={"lg"}>
            Patients
          </Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Phone Number</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {patients.map((patient) => (
                <Tr
                  key={patient.id}
                  _hover={{
                    bg: hoverBackground,
                    cursor: "pointer",
                  }}
                >
                  <Td
                    onClick={() =>
                      navigate(
                        `${ROUTES.CLIENTS}/${patient.id}/flow/${flowId}`
                      )
                    }
                  >
                    {patient.name}
                  </Td>
                  <Td>{patient.whatsappPhoneNum}</Td>
                  <Td>
                    <Button
                      onClick={() =>
                        onClickSendFlow({
                          patientId: patient.id,
                          patientName: patient.name,
                        })
                      }
                    >
                      Send Flow
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justifyContent={"space-between"}>
            <Heading fontWeight={"normal"} size={"lg"}>
              List of all responses
            </Heading>

            <Button
              aria-label="Export to CSV"
              leftIcon={<FaDownload />}
              onClick={handleExportToCSV}
              variant="outline"
            >
              Export to CSV
            </Button>
          </Flex>
          <Text color={"gray"}>{responses.length} responses</Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Text>#</Text>
                </Th>
                <Th>
                  <Text>Client Name</Text>
                </Th>
                {workflow?.fields.map((field) => {
                  return (
                    <Th
                      isNumeric={field.responseType === ResponseType.NUMBER}
                      key={field.key}
                    >
                      <Text>{field.label}</Text>
                    </Th>
                  );
                })}
                <Th>
                  <Text>date recorded</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {responses.length > 0 &&
                responses.map((response, idx) => {
                  return (
                    <Tr key={response.id}>
                      <Td>{responses.length - idx}</Td>
                      <Td cursor={"pointer"}>{response.patientName}</Td>
                      {workflow?.fields.map((field, fieldIdx) => {
                        return (
                          <Td>{response.rawData[`q_${fieldIdx + 1}`] ?? ""}</Td>
                        );
                      })}
                      <Td>
                        {new Date(response.createdAt).toLocaleDateString(
                          "en-GB"
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              {responses.length === 0 && (
                <Tr>
                  <Td
                    textAlign={"center"}
                    colSpan={
                      (workflow?.fields.length ?? 0) + NUM_ADDITIONAL_COLS
                    }
                  >
                    No responses yet
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </VStack>
      </Container>
    </>
  );
};

export default FlowResponsesPage;
