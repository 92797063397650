import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createNote, getNotesByPatientId } from "../../../api/patients";
import { getWorkflowDataList } from "../../../api/workflowData";
import { ROUTES, theme } from "../../../constants";
import { useStaffStore } from "../../../store/staffStore";
import {
  PatientActivity,
  PatientActivityType,
  PatientDto,
  PatientNoteDto,
} from "../../../types/patient";
import NotesSection from "../PatientDetail/NotesSection";
import { getGroupDateHeader, groupActivitiesByMonth } from "../utils";
import UploadModal from "../UploadModal";
import { deleteFile } from "../../../api/document";
import { useNavigate } from "react-router-dom";

interface ActivityProps {
  patient: PatientDto;
  refetchPatient: () => Promise<boolean>;
}

const Activity = ({ patient, refetchPatient }: ActivityProps) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(true);
  const [notesActivities, setNotesActivities] = useState<PatientActivity[][]>(
    []
  );
  const [tagsActivities, setTagsActivities] = useState<PatientActivity[][]>([]);
  const [responsesActivities, setResponsesActivities] = useState<
    PatientActivity[][]
  >([]);
  const [activities, setActivities] = useState<PatientActivity[][]>([]);
  const [reportActivities, setReportActivities] = useState<PatientActivity[][]>(
    []
  );
  const {
    isOpen: isNotesOpen,
    onOpen: onOpenNotes,
    onClose: onCloseNotes,
  } = useDisclosure();
  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport,
  } = useDisclosure();

  const toast = useToast();
  const [newNote, setNewNote] = useState<PatientNoteDto>({
    content: "",
    id: "",
    patientId: patient.id,
  });

  const handleDeleteReport = async (docId: string) => {
    const resp = await deleteFile(docId);
    if (resp.status === 200) {
      toast({
        title: "Report deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await refetchPatient();
      setRefresh(true);
    }
  };

  useEffect(() => {
    if (!refresh) {
      return;
    }

    const getActivities = async () => {
      const allActivities: PatientActivity[] = [];
      const notesResp = await getNotesByPatientId(patient.id);
      const notesActivities: PatientActivity[] = notesResp.data.map((note) => {
        return {
          header: (
            <>
              <Text fontWeight={"bold"} display={"inline"}>
                Note{" "}
              </Text>
              <Text fontWeight={"normal"} display={"inline"}>
                from {note.staffName}
              </Text>
            </>
          ),
          preview: (
            <Text fontSize={"sm"} fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
          type: PatientActivityType.NOTE,
          date: new Date(note.createdDate || ""),
          content: (
            <Text fontWeight={"normal"} whiteSpace={"pre-line"}>
              {note.content}
            </Text>
          ),
        };
      });
      const responsesResp = await getWorkflowDataList({
        patientIds: [patient.id],
      });
      const responsesActivities: PatientActivity[] = responsesResp.map(
        (response) => {
          const content = (
            <>
              Submitted repsonse to{" "}
              <Link
                color={`${theme}.500`}
                href={`${ROUTES.FLOWS_LIST}/${response.workflowId}${ROUTES.RESPONSES}`}
              >
                {response.workflow?.name}
              </Link>
            </>
          );
          return {
            header: (
              <Text fontWeight={"bold"} display={"inline"}>
                Form Submission
              </Text>
            ),
            preview: (
              <Text
                fontSize={"sm"}
                fontWeight={"normal"}
                whiteSpace={"pre-line"}
              >
                {content}
              </Text>
            ),
            type: PatientActivityType.RESPONSE,
            date: new Date(response.createdAt),
            content: (
              <Text fontWeight={"normal"} display={"inline"}>
                {content}
              </Text>
            ),
          };
        }
      );
      const tagsActivities: PatientActivity[] =
        patient.tags?.map((tag) => {
          return {
            header: (
              <>
                <Text fontWeight={"normal"} display={"inline"}>
                  Client Tag automatically assigned:{" "}
                </Text>
                <Text fontWeight={"bold"} display={"inline"}>
                  {tag.name}
                </Text>
              </>
            ),
            preview: (
              <Text
                fontSize={"sm"}
                fontWeight={"normal"}
                whiteSpace={"pre-line"}
              >
                {tag.description}
              </Text>
            ),
            type: PatientActivityType.TAG,
            date: tag.assigned,
            content: (
              <Text fontWeight={"normal"} display={"inline"}>
                {tag.description}
              </Text>
            ),
            staffName: "",
          };
        }) || [];
      console.log(patient.documents);
      const reportActivities: PatientActivity[] =
        patient.documents?.map((document) => {
          return {
            header: (
              <>
                <Text
                  fontWeight={"bold"}
                  display={"inline"}
                  noOfLines={1}
                  maxW={400}
                >
                  {document.label}
                </Text>
              </>
            ),
            preview: (
              <Text fontWeight={"normal"} display={"inline"}>
                Uploaded by {document.createdBy}
              </Text>
            ),
            type: PatientActivityType.REPORT,
            date: document.createdAt!,
            content: (
              <Flex justifyContent="space-around">
                <Text fontWeight={"normal"}>
                  Uploaded by {document.createdBy}
                </Text>
                <Spacer />
                <Button
                  onClick={() => navigate(`${ROUTES.REPORT}/${document.id}`)}
                >
                  Get Data
                </Button>
                <Button
                  onClick={() => handleDeleteReport(document.id)}
                  variant="ghost"
                  mr={2}
                  colorScheme="red"
                >
                  Delete
                </Button>
              </Flex>
            ),
            staffName: document.createdBy,
          };
        }) || [];

      allActivities.push(
        ...notesActivities,
        ...(tagsActivities || []),
        ...responsesActivities,
        ...(reportActivities || [])
      );
      allActivities.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      const groupedAll = groupActivitiesByMonth(allActivities);
      const groupedNotes = groupActivitiesByMonth(notesActivities);
      const groupedTags = groupActivitiesByMonth(tagsActivities);
      const groupedResponses = groupActivitiesByMonth(responsesActivities);
      const groupedReports = groupActivitiesByMonth(reportActivities);
      setActivities(groupedAll);
      setNotesActivities(groupedNotes);
      setTagsActivities(groupedTags);
      setResponsesActivities(groupedResponses);
      setReportActivities(groupedReports);
    };

    getActivities();
    setRefresh(false);
  }, [patient.id, refresh, patient.tags, patient.documents, patient]);

  const handleNotesSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const created = await createNote(newNote);
    if (created) {
      setNewNote({
        content: "",
        id: "",
        patientId: patient.id,
      });
      setRefresh(true);
      toast({
        title: "Note created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onCloseNotes();
  };

  return (
    <div>
      <Modal isOpen={isNotesOpen} onClose={onCloseNotes}>
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleNotesSubmit}>
          <Box bg={"brand"}>
            <ModalHeader color={"light"}>Add Note</ModalHeader>
            <ModalCloseButton color={"light"} />
          </Box>
          <ModalBody pb={6} maxHeight={"70vh"} overflow={"auto"}>
            <Heading size="md" my={6}>
              Create Note
            </Heading>
            <FormControl isRequired mb={4}>
              <FormLabel>Content</FormLabel>
              <Textarea
                value={newNote?.content}
                onChange={(e) =>
                  setNewNote({ ...newNote, content: e.target.value })
                }
              />
            </FormControl>
          </ModalBody>

          <ModalFooter
            borderTop={"1px"}
            borderColor={"gray.200"}
            dropShadow={""}
          >
            <Button mr={3} type="submit">
              Save
            </Button>
            <Button onClick={onCloseNotes} variant={"outline"}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UploadModal
        isReportOpen={isReportOpen}
        onOpenReport={onOpenReport}
        onCloseReport={onCloseReport}
        patientId={patient.id}
        onSubmit={async () => {
          await refetchPatient();
          setRefresh(true);
        }}
      />

      <HStack justify={"right"} w="full">
        <Button onClick={onOpenNotes}>Add Note</Button>
        <Button onClick={onOpenReport}>Add Report</Button>
      </HStack>
      <Tabs>
        <TabList>
          <Tab>All</Tab>
          <Tab>Notes</Tab>
          <Tab>Tags</Tab>
          <Tab>Responses</Tab>
          <Tab>Reports</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {activities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {notesActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {tagsActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {responsesActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
          <TabPanel>
            {reportActivities.map((group) => (
              <NotesSection
                activities={group}
                header={getGroupDateHeader(group)}
              />
            ))}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Activity;
