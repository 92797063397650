import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import { useEffect, useState } from "react";
import { PatientDto } from "../../../types/patient";
import { ProviderType } from "../../../types/workflow";
import { toTitleCase } from "../../../utils";
import { parseISO } from "date-fns";

interface PatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (patient: PatientDto) => void;
  initialData: PatientDto | null;
}

type PatientFormData = {
  name: string;
  nationality: string;
  nationalId: string;
  dob: Date;
  sex: string;
  district: string;
  email: string;
  preferredChannel: string;
  whatsappPhoneNum: string;
};

const emptyFormData: PatientFormData = {
  name: "",
  nationality: "",
  nationalId: "",
  dob: new Date(),
  sex: "",
  district: "",
  email: "",
  preferredChannel: "",
  whatsappPhoneNum: "",
};

const PatientModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
}: PatientModalProps) => {
  const [formData, setFormData] = useState<PatientFormData>({
    ...emptyFormData,
  });

  const toast = useToast();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        dob:
          typeof initialData.dob === "string"
            ? parseISO(initialData.dob)
            : initialData.dob,
        preferredChannel: initialData?.preferredChannel,
        email: initialData?.email || "",
        whatsappPhoneNum: initialData?.whatsappPhoneNum || "",
      });
    } else {
      // Reset the form fields if there's no initial data
      setFormData({
        ...emptyFormData,
        dob:
          typeof emptyFormData.dob === "string"
            ? parseISO(emptyFormData.dob)
            : emptyFormData.dob,
      });
    }
  }, [initialData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const patientDto: PatientDto = {
      id: initialData?.id || "",
      name: formData.name,
      nationality: formData.nationality,
      nationalId: formData.nationalId,
      dob: formData.dob,
      sex: formData.sex,
      district: formData.district,
      email: formData.email,
      preferredChannel: formData.preferredChannel as any,
      whatsappPhoneNum: formData.whatsappPhoneNum,
    };
    onSubmit(patientDto);
    onClose(); // Close the modal after submitting
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <Box bg={"brand"}>
          <ModalHeader color={"light"}>Add Client</ModalHeader>
          <ModalCloseButton color={"light"} />
        </Box>
        <ModalBody pb={6} maxHeight={"70vh"} overflow={"auto"}>
          <Heading size="md" my={6}>
            Client Information
          </Heading>
          <FormControl isRequired mb={4}>
            <FormLabel>Name</FormLabel>
            <Input value={formData?.name} name="name" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Nationality</FormLabel>
            <Input
              value={formData?.nationality}
              name="nationality"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>National ID</FormLabel>
            <Input
              value={formData?.nationalId}
              name="nationalId"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Date of Birth</FormLabel>
            <SingleDatepicker
              triggerVariant="input"
              name="date-input"
              date={formData.dob}
              maxDate={new Date()}
              onDateChange={(newDate: Date) =>
                setFormData((prev) => ({ ...prev, dob: newDate }))
              }
              configs={{
                dateFormat: "d/M/y",
              }}
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Sex</FormLabel>
            <Select value={formData?.sex} name="sex" onChange={handleChange}>
              <option value="">Select one</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Select>
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>District</FormLabel>
            <Input
              value={formData?.district}
              name="district"
              onChange={handleChange}
            />
          </FormControl>
          <Heading size="md" my={6}>
            Communication
          </Heading>
          <FormControl mb={4} isRequired>
            <FormLabel>Whatsapp Phone Number</FormLabel>
            <Input
              value={formData?.whatsappPhoneNum}
              name="whatsappPhoneNum"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              value={formData?.email}
              name="email"
              onChange={handleChange}
            />
          </FormControl>
          <FormControl isRequired mb={4}>
            <FormLabel>Preferred communication channel</FormLabel>
            <RadioGroup
              onChange={(newValue) =>
                setFormData((prev) => ({ ...prev, preferredChannel: newValue }))
              }
              value={formData.preferredChannel}
            >
              <HStack>
                {Object.keys(ProviderType).map((provider) => {
                  return (
                    <Radio value={provider.toString()}>
                      {toTitleCase(provider)}
                    </Radio>
                  );
                })}
              </HStack>
            </RadioGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter borderTop={"1px"} borderColor={"gray.200"} dropShadow={""}>
          <Button mr={3} type="submit">
            Save
          </Button>
          <Button onClick={onClose} variant={"outline"}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatientModal;
