import { Flex, FlexProps, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { theme } from "../../constants";

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: string;
}

const NavItem = ({ icon, children }: NavItemProps) => {
  return (
    <Link
      href="#"
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <VStack
        align="center"
        p={6}
        role="group"
        cursor="pointer"
        _hover={{
          bg: `${theme}.900`,
          color: "white",
        }}
        spacing={2}
      >
        {icon && (
          <Icon
            fontSize="4xl"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        <Text fontSize="lg">{children}</Text>
      </VStack>
    </Link>
  );
};

export default NavItem;
