import {
  Button,
  Container,
  HStack,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { rrulestr } from "rrule";
import { getWorkflowDataCount } from "../../api/workflowData";
import { getWorkflowList } from "../../api/workflows";
import FlowCard from "../../components/Flows/FlowCard";
import Header from "../../components/Head";
import { ROUTES, theme } from "../../constants";
import { WorkflowCardData, WorkflowStatus } from "../../types/workflow";

const FlowPage = () => {
  const [flows, setFlows] = useState<WorkflowCardData[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getFlows() {
      // retrieve flows from backend
      const workflowDtos = await getWorkflowList();
      const cardDataDict: Map<string, WorkflowCardData> = new Map();
      workflowDtos.forEach((dto) => {
        cardDataDict.set(dto.id, {
          workflowId: dto.id,
          status: dto.status,
          title: dto.name,
          frequency: rrulestr(dto.schedule),
          numRespondents: dto.patientIds.length,
          numResponses: 0,
        });
      });

      // get the count of the responses for the workflow ids
      const responsesCountResp = await getWorkflowDataCount(
        workflowDtos.map((dto) => dto.id)
      );
      responsesCountResp?.forEach((wfCount) => {
        const workflowId = wfCount.workflowId;
        const cardData = cardDataDict.get(workflowId);

        if (cardData) {
          cardDataDict.set(workflowId, {
            ...cardData,
            numResponses: wfCount.count,
          });
        }
      });

      // set state
      setFlows(Array.from(cardDataDict.values()));
    }

    getFlows();
  }, []);

  const activeFlows = flows
    .filter((flow) => flow.status === WorkflowStatus.PUBLISHED)
    .reverse();
  const deprecatedFlows = flows
    .filter((flow) => flow.status === WorkflowStatus.DEPRECATED)
    .reverse();

  return (
    <>
      <Container maxW={"5xl"} minH={`calc(100vh - ${200}px)`}>
        <Header description="Flows Page" />
        <HStack justifyContent={"space-between"} alignItems={"center"} mb={5}>
          <Heading>Flows</Heading>
          <Button
            onClick={() => navigate(ROUTES.SELECT_TEMPLATE)}
            leftIcon={<FaPlus />}
            colorScheme={theme}
          >
            New
          </Button>
        </HStack>
        {(!flows || flows.length === 0) && (
          <Stack
            w="full"
            h={`calc(100vh - ${200}px)`}
            justifyContent={"center"}
            alignItems={"center"}
            spacing={3}
          >
            <Text>
              You currently don’t have any active flows, create one now!
            </Text>
            <Button
              onClick={() => navigate(ROUTES.SELECT_TEMPLATE)}
              leftIcon={<FaPlus />}
              colorScheme={theme}
            >
              Create One!
            </Button>
          </Stack>
        )}
        {flows && (
          <VStack align={"left"} spacing={5} mt={10}>
            {activeFlows.length > 0 && (
              <Heading size="lg">Active Flows</Heading>
            )}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {activeFlows?.map((flow) => {
                return (
                  <FlowCard
                    key={flow.workflowId}
                    status={flow.status}
                    onClick={() =>
                      navigate(
                        `${ROUTES.FLOWS_LIST}/${flow.workflowId}${ROUTES.RESPONSES}`
                      )
                    }
                    name={flow.title}
                    frequency={flow.frequency}
                    numPatients={flow.numRespondents}
                    numResponses={flow.numResponses}
                  />
                );
              })}
            </SimpleGrid>
          </VStack>
        )}

        {flows && (
          <VStack align={"left"} spacing={5} mt={10}>
            {deprecatedFlows.length > 0 && (
              <Heading size="lg">Archived Flows</Heading>
            )}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              {deprecatedFlows?.map((flow) => {
                return (
                  <FlowCard
                    key={flow.workflowId}
                    status={flow.status}
                    onClick={() =>
                      navigate(
                        `${ROUTES.FLOWS_LIST}/${flow.workflowId}${ROUTES.RESPONSES}`
                      )
                    }
                    name={flow.title}
                    frequency={flow.frequency}
                    numPatients={flow.numRespondents}
                    numResponses={flow.numResponses}
                  />
                );
              })}
            </SimpleGrid>
          </VStack>
        )}
      </Container>
    </>
  );
};

export default FlowPage;
