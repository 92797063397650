import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

interface TestFlowModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmTest: () => void;
  patientId: string;
  patientName: string;
}

const TestFlowModal = ({
  onConfirmTest,
  isOpen,
  onClose,
  patientId,
  patientName,
}: TestFlowModalProps) => {
  const testFlow = () => {
    onConfirmTest();
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Test Flow</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align={"left"} spacing={3}>
              <Text>Send flow to {patientName}?</Text>
              <Text fontSize="sm" color="gray.500">
                Message may take up to 1 minute to be sent to user.
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={testFlow}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TestFlowModal;
