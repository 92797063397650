import {
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import { useEffect, useState } from "react";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Frequency, RRule } from "rrule";
import { getAllPatients } from "../../api/patients";
import FlowTemplateCard from "../../components/Flows/FlowTemplateCard";
import Header from "../../components/Head";
import { ROUTES, messagingApps, theme } from "../../constants";
import { workflowTemplates } from "../../constants/templates";
import {
  DEFAULT_SELECTED_HOUR,
  dayOptions,
  formatHourStr,
  frequencyOptions,
  hours,
} from "../../constants/time";
import { PatientDto } from "../../types/patient";
import {
  CreateWorkflowDto,
  ProviderType,
  WorkflowTemplateId,
} from "../../types/workflow";

const SelectTemplatePage = () => {
  const [templateId, setTemplateId] = useState<WorkflowTemplateId>();
  const [communicationChannel, setCommunicationChannel] =
    useState<ProviderType>(messagingApps[0]);
  const [frequency, setFrequency] = useState<Frequency>(Frequency.DAILY);
  const [selectedDay, setSelectedDay] = useState<number>(RRule.MO.weekday);
  const [selectedHour, setSelectedHour] = useState<number>(
    DEFAULT_SELECTED_HOUR
  );
  const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);

  const [patients, setPatients] = useState<PatientDto[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getPatients() {
      const patientResp = await getAllPatients();
      setPatients(patientResp.data.data ?? []);
    }

    getPatients();
  }, []);

  const isValid = () => {
    // Check if templateId is set
    if (templateId === undefined) {
      return false;
    }

    // Check if communicationChannel is set
    if (!messagingApps.includes(communicationChannel)) {
      return false;
    }

    // Check if frequency is set
    if (frequency === undefined) {
      return false;
    }

    // Check if selectedDay is set
    if (selectedDay === undefined || isNaN(selectedDay)) {
      return false;
    }

    // Check if selectedHour is set
    if (selectedHour === undefined || isNaN(selectedHour)) {
      return false;
    }

    // Check if patientIds array is not empty
    // if (!patientIds || patientIds.length === 0) {
    //   return false;
    // }

    // All checks passed, return true
    return true;
  };

  const handleSave = () => {
    if (!templateId) {
      return;
    }

    const now = new Date();
    // Create a new Date object representing the next occurrence of the selected day and hour
    const nextOccurrence = new Date(now);
    nextOccurrence.setDate(
      now.getDate() + ((7 + selectedDay - now.getDay()) % 7)
    );
    nextOccurrence.setHours(selectedHour, 0, 0);

    const recurrenceRule = new RRule({
      freq: frequency,
      wkst: nextOccurrence.getUTCDay(),
      byhour: nextOccurrence.getUTCHours(),
      byminute: nextOccurrence.getUTCMinutes(),
      bysecond: nextOccurrence.getUTCSeconds(),
    });

    const templateDto = workflowTemplates[templateId]?.workflowTemplateDto;
    if (!templateDto) {
      return;
    }

    const createWorkflowDto: CreateWorkflowDto = {
      ...templateDto,
      channel: communicationChannel,
      patientIds: selectedPatientIds,
      schedule: recurrenceRule.toString(),
      templateId: templateId,
    };

    navigate(ROUTES.CREATE_FLOW, {
      state: {
        createWorkflowDto,
      },
    });
  };

  const isFormValid = isValid();

  const displayTimezone = () => {
    // Get the current date/time
    const now = new Date();

    // Get the timezone offset in minutes (Note: The offset is in minutes, and it's positive if the local timezone is behind GMT and negative if it's ahead)
    const timezoneOffset = now.getTimezoneOffset();

    // Convert the offset to hours and determine the sign
    const offsetHours = Math.abs(timezoneOffset) / 60;
    const sign = timezoneOffset > 0 ? "-" : "+";

    // Format the timezone in GMT+X or GMT-X format
    const formattedTimezone = `${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    } GMT${sign}${offsetHours.toString().padStart(2, "0")}`;
    return formattedTimezone;
  };

  return (
    <>
      <Container maxW={"5xl"} minH={`calc(100vh - ${200}px)`}>
        <Header description="Flows Page" />
        <VStack spacing={3} alignItems={"flex-start"}>
          <Heading>Create a flow</Heading>
          <Text>Choose one of the templates below to begin</Text>
        </VStack>
        <Flex wrap={"wrap"}>
          {Object.values(workflowTemplates).map((template) => {
            return (
              <FlowTemplateCard
                m={5}
                title={template.title}
                description={template.description}
                id={template.id}
                onClick={() =>
                  setTemplateId((prevValue) => {
                    return prevValue === template.id ? undefined : template.id;
                  })
                }
                selected={templateId === template.id}
              />
            );
          })}
        </Flex>
        <Stack mt={10} spacing={5}>
          <Text as="b">Communication</Text>
          <HStack spacing={20} alignItems={"flex-start"}>
            <Text width={"100px"}>Channel</Text>
            <RadioGroup
              onChange={(value) =>
                setCommunicationChannel(value as ProviderType)
              }
              value={communicationChannel}
            >
              <VStack alignItems="flex-start" spacing={3}>
                <Radio
                  key={ProviderType.WHATSAPP}
                  value={ProviderType.WHATSAPP}
                >
                  <HStack>
                    <Text>Whatsapp</Text>
                    <FaWhatsapp />
                  </HStack>
                </Radio>
                <Tooltip
                  label="Contact Speedback support to activate this channel"
                  shouldWrapChildren
                >
                  <Radio
                    isDisabled
                    key={ProviderType.FB_MESSENGER}
                    value={ProviderType.FB_MESSENGER}
                  >
                    <HStack>
                      <Text>Facebook Messenger</Text>
                      <FaFacebookMessenger />
                    </HStack>
                  </Radio>
                </Tooltip>
                <Tooltip
                  label="Contact Speedback support to activate this channel"
                  shouldWrapChildren
                >
                  <Radio
                    isDisabled
                    key={ProviderType.TELEGRAM}
                    value={ProviderType.TELEGRAM}
                  >
                    <HStack>
                      <Text>Telegram</Text>
                      <FaTelegramPlane />
                    </HStack>
                  </Radio>
                </Tooltip>
                <Text fontSize={"xs"}>Notification charges apply</Text>
              </VStack>
            </RadioGroup>
          </HStack>
          <HStack spacing={20} alignItems={"flex-start"}>
            <Text width={"100px"}>Respondents</Text>
            <AutoComplete
              multiple
              rollNavigation
              onChange={(vals) =>
                setSelectedPatientIds(
                  patients
                    .filter((cl) => vals.includes(cl.name))
                    .map((cl) => cl.id)
                )
              }
            >
              <AutoCompleteInput
                w="fit-content"
                placeholder="Select patients"
                autoFocus
              >
                {({ tags }) =>
                  tags.map((tag, tid) => (
                    <AutoCompleteTag
                      key={tid}
                      label={tag.label}
                      onRemove={tag.onRemove}
                    />
                  ))
                }
              </AutoCompleteInput>
              <AutoCompleteList>
                {patients.length > 0 &&
                  patients.map((patient, idx) => (
                    <AutoCompleteItem
                      key={`option-${idx}`}
                      value={patient.name}
                    >
                      {patient.name}
                    </AutoCompleteItem>
                  ))}
              </AutoCompleteList>
            </AutoComplete>
          </HStack>
          <Text as="b">Schedule (Following Timezone: {displayTimezone()})</Text>
          <HStack spacing={20} alignItems={"flex-start"}>
            <Text width={"100px"}>Run every:</Text>
            <Select
              width={"300px"}
              value={frequency}
              defaultValue={RRule.DAILY}
              onChange={(e) => setFrequency(parseInt(e.target.value))}
            >
              {frequencyOptions.map((freq) => (
                <option key={freq.value} value={freq.value}>
                  {freq.name}
                </option>
              ))}
            </Select>
          </HStack>
          {frequency === Frequency.WEEKLY && (
            <HStack spacing={20} alignItems={"flex-start"}>
              <Text width={"100px"}>On:</Text>
              <Select
                width={"300px"}
                value={selectedDay}
                defaultValue={RRule.MO.weekday}
                onChange={(e) => setSelectedDay(parseInt(e.target.value))}
              >
                {dayOptions.map((day) => (
                  <option key={day.value.weekday} value={day.value.weekday}>
                    {day.name}
                  </option>
                ))}
              </Select>
            </HStack>
          )}
          <HStack spacing={20} alignItems={"flex-start"}>
            <Text width={"100px"}>At:</Text>
            <Select
              width={"300px"}
              value={selectedHour}
              defaultValue={DEFAULT_SELECTED_HOUR}
              onChange={(e) => setSelectedHour(parseInt(e.target.value))}
            >
              {hours.map((hour) => (
                <option key={hour} value={hour}>
                  {formatHourStr(hour)}
                </option>
              ))}
            </Select>
          </HStack>
          <HStack justifyContent={"flex-end"}>
            <Button
              isDisabled={!isFormValid}
              colorScheme={theme}
              onClick={handleSave}
            >
              Save and Continue
            </Button>
          </HStack>
        </Stack>
      </Container>
    </>
  );
};

export default SelectTemplatePage;
