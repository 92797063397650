import { RRule } from "rrule";
import { Property } from "../types/tag";
import {
  CreateWorkflowDto,
  OptionsLayout,
  ProviderType,
  ResponseType,
  WorkflowField,
  WorkflowTemplateId,
} from "../types/workflow";

export const BP_WORKFLOW_FIELDS: WorkflowField[] = [
  {
    label: "Systolic mm hg",
    isMultiSelect: false,
    key: "q_1",
    question: "What is your Upper Range?",
    responseType: ResponseType.PROPERTY,
    property: Property.BLOOD_PRESSURE_SYSTOLIC,
    numberOptions: {
      min: 0,
      max: 400,
    },
    required: true,
  },
  {
    label: "Diastolic mm hg",
    isMultiSelect: false,
    key: "q_2",
    question: "What is your Lower Range?",
    responseType: ResponseType.PROPERTY,
    property: Property.BLOOD_PRESSURE_DIASTOLIC,
    numberOptions: {
      min: 0,
      max: 400,
    },
    required: true,
  },
];

export const bpWorkflow: CreateWorkflowDto = {
  channel: ProviderType.WHATSAPP,
  patientIds: [],
  fields: BP_WORKFLOW_FIELDS,
  message:
    "Hi ${name}! Time for a quick health check-in 🩺💓 Please take a moment to log your daily blood pressure readings. Your well-being matters, and tracking helps us ensure you're on the path to a healthy heart! 🩸📊",
  name: "Blood Pressure Tracker",
  schedule: new RRule().toString(),
  postSubmitMsg: {
    header: "Thank you for submitting your blood pressure readings!",
    body: "Your health is important to us. Keep up the good work! 🩸💪",
  },
};

const FITNESS_Q2_OPTIONS: Record<string, string> = {
  "< 10": "Mas mababa pa sa 10 minutes",
  "10-15": "10-15 minutes",
  "16-20": "16-20 minutes",
  "21-30": "21-30 minutes",
  "> 30": "Higit pa sa 30 minutes",
};

const FITNESS_SERVING_OPTIONS: Record<string, string> = {
  "0": "Wala (0)",
  "1-2": "1-2 servings",
  "3-5": "3-5 servings",
  "> 5": "Higit pa sa 5 servings",
};

export const FITNESS_WORKFLOW_FIELDS: WorkflowField[] = [
  {
    label: "Sleep quality",
    isMultiSelect: false,
    key: "q_1",
    question:
      "Sa sukat na 1-5, gaano kaganda ang iyong tulog kagabi? 😴 [scale: 1 (lubos na hindi maganda) to 5 (lubos na maganda)]",
    responseType: ResponseType.RADIO,
    options: Object.fromEntries(
      Array.from({ length: 5 }, (_, i) => [
        (i + 1).toString(),
        (i + 1).toString(),
      ])
    ),
    layout: OptionsLayout.HORIZONTAL,
    required: true,
  },
  {
    label: "Workout ",
    isMultiSelect: false,
    key: "q_2",
    question: "Ilang minuto ang nilaan mo para mag-exercise kahapon? ⏰",
    responseType: ResponseType.RADIO,
    options: FITNESS_Q2_OPTIONS,
    layout: OptionsLayout.VERTICAL,
    required: true,
  },
  {
    label: "Vegetables",
    isMultiSelect: false,
    key: "q_3",
    question:
      "Ilang servings ng gulay at prutas na sinlaki ng palad ang iyong nakain kahapon? 🥦🍎",
    responseType: ResponseType.RADIO,
    options: FITNESS_SERVING_OPTIONS,
    layout: OptionsLayout.VERTICAL,
    required: true,
  },
  {
    label: "Protein",
    isMultiSelect: false,
    key: "q_4",
    question:
      "Ilang servings ng protein (halimbawa: itlog, karne, tofu, isda, manok, beans/monggo, mais) na sinlaki ng palad ang nakain mo kahapon? 🍳🥩",
    responseType: ResponseType.SHORT_TEXT,
    options: FITNESS_SERVING_OPTIONS,
    layout: OptionsLayout.VERTICAL,
    required: true,
  },
  {
    label: "Lifestyle",
    isMultiSelect: false,
    key: "q_5",
    question:
      "Base sa mga nagawa mo kahapon, gaano ka nasiyahan sa kasalukuyan mong mga kinagawian (routines) at pamumuhay (lifestyle)? 💪 [scale: 1 (lubos na hindi satisfied) - 5 (lubos na satisfied)]",
    responseType: ResponseType.RADIO,
    options: FITNESS_SERVING_OPTIONS,
    layout: OptionsLayout.HORIZONTAL,
    required: true,
  },
  {
    label: "Questions",
    isMultiSelect: false,
    key: "q_6",
    question: "May gusto ka bang itanong kay coach?",
    responseType: ResponseType.SHORT_TEXT,
    required: false,
  },
];

export const fitnessWorkflow: CreateWorkflowDto = {
  channel: ProviderType.WHATSAPP,
  patientIds: [],
  fields: FITNESS_WORKFLOW_FIELDS,
  message:
    "G na ba para sa isa na namang ByaHealthy na araw? Sagutan na ang daily tracker—ilagay ang stats at sama-sama nating i-achieve and inyong goals, ByaHeroes! 💥💪\n",
  name: "Fitness Tracker",
  schedule: new RRule().toString(),
  postSubmitMsg: {
    header: "Yehey,",
    body: "Salamat sa pagsagot ng daily intake form, ByaHero! 🚴‍♂️💨 Isang hakbang na naman patungo sa mas malusog na buhay para sa sarili at sa inyong pamilya! 🌟 Huwag kalimutan ang susunod na session ha? Tuloy-tuloy lang! 👌",
  },
};

type WorkflowTemplate = {
  id: WorkflowTemplateId;
  title: string;
  description: string;
  workflowTemplateDto: CreateWorkflowDto;
};

export const workflowTemplates: Record<WorkflowTemplateId, WorkflowTemplate> = {
  BLOOD_PRESSURE: {
    id: WorkflowTemplateId.BLOOD_PRESSURE,
    title: "Blood Pressure Tracker",
    description: "Monitoring of systolic and diastolic readings",
    workflowTemplateDto: bpWorkflow,
  },
  FITNESS_TRACKER: {
    id: WorkflowTemplateId.FITNESS_TRACKER,
    title: "Fitness Tracker",
    description: "Rate your adherence with exercise, sleep and food",
    workflowTemplateDto: fitnessWorkflow,
  },
  CUSTOM: {
    id: WorkflowTemplateId.CUSTOM,
    title: "Custom",
    description: "Custom Workflow",
    workflowTemplateDto: {
      channel: ProviderType.WHATSAPP,
      patientIds: [],
      fields: [],
      message: "",
      name: "Custom",
      schedule: new RRule().toString(),
      postSubmitMsg: {
        body: "Thanks for submitting, have a nice day!",
        header: "Thank you!",
      },
    },
  },
};
