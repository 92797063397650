import axios from "axios";
import {
  PatientDto,
  PatientNoteDto,
  PatientQueryParams,
} from "../types/patient";
import axiosAuth from "./axiosAuth";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "";

// Queries

export const getPatientList = async (params: PatientQueryParams) => {
  return axiosAuth.get(`${API_URL}/patient`, { params });
};

export const getByPatientIds = async (patientIds: string[]) => {
  return axiosAuth.get<PatientDto[]>(`${API_URL}/patient/list`, {
    params: { patientIds },
  });
};

export const getAllPatients = async () => {
  return axiosAuth.get<{ data: PatientDto[]; total: number }>(
    `${API_URL}/patient/all`
  );
};

export const getPatientById = async (id: string) => {
  return axios.get<PatientDto>(`${API_URL}/patient/find?id=${id}`);
};

export const createPatient = async (patient: PatientDto) => {
  return axiosAuth.post(`${API_URL}/patient`, patient);
};

export const bulkCreatePatients = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosAuth.post(`${API_URL}/patient/bulk-create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updatePatient = async (id: string, patient: PatientDto) => {
  return axiosAuth.put(`${API_URL}/patient/${id}`, patient);
};

export const deletePatient = async (id: string) => {
  return axiosAuth.delete(`${API_URL}/patient/${id}`);
};

export const getNoteById = async (id: string) => {
  return axiosAuth.get<PatientNoteDto>(`${API_URL}/patient/notes/${id}`);
};

export const getNotesByPatientId = (patientId: string) => {
  return axiosAuth.get<PatientNoteDto[]>(`${API_URL}/patient/notes`, {
    params: { patientId },
  });
};

export const createNote = async (note: PatientNoteDto) => {
  return axiosAuth.post(`${API_URL}/patient/notes`, note);
};

export const updateNote = async (id: string, note: PatientNoteDto) => {
  return axiosAuth.put(`${API_URL}/patient/notes/${id}`, note);
};

export const deleteNote = async (id: string) => {
  return axiosAuth.delete(`${API_URL}/patient/notes/${id}`);
};

export const searchNote = async (req: {
  patientId: string;
  search: string;
}): Promise<PatientNoteDto[] | null> => {
  const res = await axiosAuth.get<PatientNoteDto[]>(
    `${API_URL}/patient/notes/search`,
    {
      params: req,
    }
  );

  return res.data;
};
