import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Box,
  Text,
  VStack,
} from "@chakra-ui/react";
import { theme } from "../../../constants";
import { validateCSVForBulkUpload } from "../../../utils";
interface BulkPatientUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (file: File) => void;
}

const BulkPatientUploadModal = ({
  isOpen,
  onClose,
  onSubmit,
}: BulkPatientUploadModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      const isValid = await validateCSVForBulkUpload(selectedFile);
      if (isValid) {
        setFile(selectedFile);
      } else {
        toast({
          title: "Invalid CSV format",
          description: "Please ensure your CSV file has the correct columns.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setFile(null);
      }
    } else {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setFile(null);
    }
  };

  const handleSubmit = () => {
    if (file) {
      onSubmit(file);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box bg={"brand"}>
          <ModalHeader color={"light"}>Bulk Upload Clients</ModalHeader>
          <ModalCloseButton color={"light"} />
        </Box>
        <ModalBody pb={6}>
          <VStack spacing={4} align="stretch">
            <Text>Upload a CSV file containing client information.</Text>
            <FormControl>
              <FormLabel>Upload CSV File</FormLabel>
              <Input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter borderTop={"1px"} borderColor={"gray.200"}>
          <Button
            colorScheme={theme}
            mr={3}
            onClick={handleSubmit}
            isDisabled={!file}
          >
            Upload
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BulkPatientUploadModal;
