import { TagDto } from "../types/tag";
import axiosAuth from "./axiosAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

// Queries

// Fetches all tags
export const getAllTags = (page: number, size: number) => {
  return axiosAuth.get<{ data: TagDto[]; total: number }>(`${API_URL}/tags`, {
    params: {
      page,
      size,
    },
  });
};

// Fetches a single tag by ID
export const getTagById = (id: string) => {
  return axiosAuth.get<TagDto>(`${API_URL}/tags/${id}`);
};

// Creates a new tag
export const createTag = (tag: TagDto) => {
  return axiosAuth.post(`${API_URL}/tags`, tag);
};

// Updates an existing tag
export const updateTag = (id: string, tag: TagDto) => {
  return axiosAuth.put(`${API_URL}/tags/${id}`, tag);
};

// Deletes a tag by ID
export const deleteTag = (id: string) => {
  return axiosAuth.delete(`${API_URL}/tags/${id}`);
};
