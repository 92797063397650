import axios from "axios";
import { LoginDto, RegisterParams, ResetPasswordDto } from "../types/auth";

const API_URL = process.env.REACT_APP_API_URL || "";

// Note these do not use axiosAuth as they are not authenticated
export const login = (email: string, password: string) => {
  return axios.post<LoginDto>(
    `${API_URL}/auth/login`,
    { email, password },
    { withCredentials: true }
  );
};

export const register = (params: RegisterParams) => {
  return axios.post<LoginDto>(`${API_URL}/auth/register`, params, {
    withCredentials: true,
  });
};

export const logout = () => {
  return axios.post(`${API_URL}/auth/logout`, {}, { withCredentials: true });
};

export const forgotPassword = (email: string) => {
  try {
    return axios.post(`${API_URL}/auth/forgot-password`, { email });
  } catch (error) {
    throw error;
  }
};

export const resetPassword = (
  accessToken: string,
  password: string
) => {
  return axios.post<ResetPasswordDto>(`${API_URL}/auth/reset-password`, {
    accessToken, password
  });
};
